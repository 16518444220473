import { useAppContext } from '../../../../context'
import {
    Alert,
    Box,
    Container,
    ExpandableSection,
    Link,
    SpaceBetween,
    StatusIndicator,
    Popover,
} from '@amzn/awsui-components-react'
import React, { useState, useEffect } from 'react'
import TeamAllocationSummaryHeaderPanel from './TeamAllocationSummaryHeaderPanel'
import {
    generateTeamAllocationSummaryGridOptions,
    getFormatFixedColumns,
    is_object_has_target,
    nullOutZeroAndDivideValuesByNumberOfWeek,
} from './Util'
import useStore from '../../../Store'
import { convertToLocalTime } from '../../../common/Util'
import {
    getDateStringYearMonth,
    getLastFriday,
    getNextUpdatedStartAtTimeString,
    isDateEnabled,
    isMonthEnabled,
} from '../../reusable/AllocationUtils'
import BusinessEntityRefresh from '../../reusable/BusinessEntityRefresh'
import {
    AlertTypes,
    PHONE_TOOL_PREFIX,
    STATUS_INDICATOR_TYPES,
    TIME_STRING,
    YEAR_MONTH_REGEX,
} from '../../../Constant'
import ValueWithLabelBox from '../../reusable/ValueWithLabelBox'
import './ManagerPage.scss'
import { AgGridReact } from 'ag-grid-react'

const TEAM_NAME_LIMIT = 48
const FIXED_COLUMNS_INDEX = 3

// custom group header component: get attributes from props
const customTeamHeaderGroup = (props) => {
    const { displayName, teamColumn, isMonthlyView, date } = props
    const name =
        displayName.length > TEAM_NAME_LIMIT
            ? `${displayName.slice(0, TEAM_NAME_LIMIT - 1)}...`
            : displayName
    if (!teamColumn) {
        return name
    }
    const hasUnscopedWeek = is_object_has_target(teamColumn.scoped_weeks, false)

    return (
        <Popover
            fixedWidth
            renderWithPortal
            dismissButton={false}
            position='top'
            size='medium'
            triggerType='custom'
            content={
                <SpaceBetween size='l'>
                    <ValueWithLabelBox label='Team Allocation'>
                        <Link
                            href={
                                isMonthlyView
                                    ? `/history/team/${teamColumn.colId}`
                                    : `/allocation/${teamColumn.colId}/${date}`
                            }
                            external
                            variant='secondary'
                        >
                            {displayName}
                        </Link>
                    </ValueWithLabelBox>
                    <ValueWithLabelBox label='Primary Contact'>
                        <Link
                            href={`${PHONE_TOOL_PREFIX}/${teamColumn.primary_alias}`}
                            external
                            variant='secondary'
                        >
                            {teamColumn.primary_alias}
                        </Link>
                    </ValueWithLabelBox>
                    <ValueWithLabelBox label='Allocation Submission Status'>
                        {Object.keys(teamColumn.scoped_weeks).map((week) => {
                            return teamColumn.scoped_weeks[week] ? (
                                <StatusIndicator
                                    key={`${teamColumn.colId}-${week}.`}
                                >{`Submitted :${week}.`}</StatusIndicator>
                            ) : (
                                <StatusIndicator
                                    key={`${teamColumn.colId}-${week}.`}
                                    type={STATUS_INDICATOR_TYPES.WARNING}
                                >{`Not submitted: ${week}.`}</StatusIndicator>
                            )
                        })}
                    </ValueWithLabelBox>
                    <ValueWithLabelBox label='HR Data Last Updated At'>
                        {convertToLocalTime(teamColumn.hr_headcount_last_updated_at)}
                    </ValueWithLabelBox>
                    <ValueWithLabelBox label='HR Data Next Update Starts At'>
                        {getNextUpdatedStartAtTimeString(teamColumn.hr_headcount_last_updated_at)}
                    </ValueWithLabelBox>
                </SpaceBetween>
            }
        >
            {
                <StatusIndicator
                    type={
                        hasUnscopedWeek
                            ? STATUS_INDICATOR_TYPES.WARNING
                            : STATUS_INDICATOR_TYPES.INFO
                    }
                    colorOverride={hasUnscopedWeek ? undefined : 'grey'}
                >
                    {name}
                </StatusIndicator>
            }
        </Popover>
    )
}

const ManagerPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const lastFridayYearMonth = getDateStringYearMonth(getLastFriday(new Date()))

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [gridApi, setGridApi] = useState<any>(null)
    const [isWeekClosed, setIsWeekClosed] = useState<boolean>(false)
    const [myTeams, setMyTeams] = useState<any[]>([])
    const [date, setDate] = useState<string>(lastFridayYearMonth)
    const [dateErrorText, setDateErrorText] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [alertContent, setAlertContent] = useState<string>('')
    const [isWeekCloseLoading, setWeekCloseIsLoading] = useState<boolean>(false)
    const [teamColumns, setTeamColumns] = useState<any>([])
    const [teamRows, setTeamRows] = useState<any>(null)
    const [isMonthlyView, setIsMonthlyView] = useState<boolean>(true)
    const [closedMonths, setClosedMonths] = useState<string[]>([])

    const onGridReady = (params) => {
        setGridApi(params.api)
        params.api.setDomLayout('autoHeight')
    }

    const onEstimateModelUpdated = (params) => {
        if (params.api.getDisplayedRowCount() === 0) {
            params.api.showNoRowsOverlay()
        }
    }

    const handleExport = () => {
        const now = new Date()
        gridApi?.exportDataAsExcel({
            fileName: `Falcon Team Allocation Summary ${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx`,
            shouldRowBeSkipped: (params) => params.node.footer,
            processRowGroupCallback: (params) => params.node.key,
        })
    }

    const getSummaryColumnsRows = () => {
        const orgId = myTeams[0]['org_id']
        const team_ids = myTeams.map((team) => team['team_id']).join(',')
        const url = `/team-allocation-summary/business-entity/${selectBusinessEntity.id}/allocation-${isMonthlyView ? 'month' : 'week'}/${date}?team_ids=${team_ids}`

        setIsLoading(true)
        gridApi.showLoadingOverlay()
        getAllOrgsByBusinessEntityDate(orgId)

        apiClient
            .get(url)
            .then((res) => {
                const cols = res.data.cols
                const rows = res.data.rows
                let numberOfWeeksInMonth = 1

                if (isMonthlyView) {
                    if (cols.length < FIXED_COLUMNS_INDEX) {
                        return
                    }
                    numberOfWeeksInMonth = Object.keys(
                        cols[FIXED_COLUMNS_INDEX].scoped_weeks,
                    ).length
                }
                setTeamColumns(
                    getFormatFixedColumns(cols, customTeamHeaderGroup, {
                        isMonthlyView,
                        date,
                    }),
                )
                setTeamRows(nullOutZeroAndDivideValuesByNumberOfWeek(rows, numberOfWeeksInMonth))
            })
            .catch((error) => {
                console.error(error)
                setTeamRows([])
            })
            .finally(() => setIsLoading(false))
    }

    const getMyTeamsClosedMonths = () => {
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then(async (response) => {
                const myTeams = response.data
                if (!myTeams.length) {
                    setAlertContent('No registered team found.')
                    return
                }
                await apiClient
                    .get(`/close-status/orgs/${myTeams[0]['org_id']}/months`)
                    .then((response) => {
                        const months = response.data
                        if (months?.length) {
                            setClosedMonths(months.sort())
                            setDate(months[months.length - 1])
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })

                setAlertContent('')
                setMyTeams(myTeams)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getAllOrgsByBusinessEntityDate = (orgId: string) => {
        setWeekCloseIsLoading(true)
        apiClient
            .get(`/admin/dashboard/business-entity/${selectBusinessEntity.id}/week/${date}`)
            .then((response) => {
                const allOrgs = response.data
                const org = allOrgs.find((org) => org.org_id === orgId)
                if (!org) {
                    setAlertContent('No organization found for registered team.')
                    return
                }

                setIsWeekClosed(org.week_closed)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setWeekCloseIsLoading(false))
    }

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getMyTeamsClosedMonths)
    }, [selectBusinessEntity])

    useEffect(() => {
        const dateObject = new Date(date + TIME_STRING)

        if (!gridApi) {
            return
        }
        if (!myTeams.length) {
            setTeamRows([])
            setIsLoading(false)
            return
        }
        if (isMonthlyView) {
            if (!YEAR_MONTH_REGEX.test(date)) {
                setDateErrorText('Invalid date. Use YYYY/MM format. Closed month only.')
                return
            }
            if (!closedMonths) {
                setAlertContent('No closed month found. Contact admin for more information.')
                setIsLoading(false)
                return
            }
        } else if (!isDateEnabled(dateObject)) {
            setDateErrorText('Invalid date. Use YYYY/MM/DD format. Friday only.')
            return
        }

        setDateErrorText('')
        getSummaryColumnsRows()
    }, [myTeams, date])

    return (
        <Container>
            <ExpandableSection defaultExpanded headerText='Team Allocation Summary'>
                <Box margin={{ top: 's' }}>
                    {alertContent ? (
                        <Alert statusIconAriaLabel='Warning' type={AlertTypes.WARNING}>
                            {alertContent}
                        </Alert>
                    ) : (
                        <Container
                            fitHeight
                            header={
                                <TeamAllocationSummaryHeaderPanel
                                    isWeekCloseLoading={isWeekCloseLoading}
                                    isWeekClosed={isWeekClosed}
                                    isLoading={isLoading}
                                    dateErrorText={dateErrorText}
                                    date={date}
                                    onDateChange={setDate}
                                    onExportClick={handleExport}
                                    isMonthlyView={isMonthlyView}
                                    onIsMonthlyViewChange={setIsMonthlyView}
                                    closedMonths={closedMonths}
                                />
                            }
                        >
                            <div className='ag-theme-quartz' style={{ height: 'fit-content' }}>
                                <AgGridReact
                                    rowData={teamRows}
                                    columnDefs={teamColumns}
                                    onGridReady={onGridReady}
                                    onModelUpdated={onEstimateModelUpdated}
                                    gridOptions={generateTeamAllocationSummaryGridOptions()}
                                    groupTotalRow={'bottom'}
                                    grandTotalRow={'bottom'}
                                    autoGroupColumnDef={{
                                        headerName: teamColumns?.[0]?.headerName,
                                        pinned: 'left',
                                    }}
                                />
                            </div>
                        </Container>
                    )}
                </Box>
            </ExpandableSection>
        </Container>
    )
}

export default ManagerPage

import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Modal,
    Input,
    SpaceBetween,
    Select,
    Textarea,
} from '@amzn/awsui-components-react/polaris'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { useAppContext } from '../../../context'
import {
    generateId,
    getDateFormat,
    isProgramProjectActive,
    isProgramProjectActiveForYear,
} from '../../common/Util'
import {
    AlertTypes,
    ModalModes,
    PROJECT_NAME_VALIDATION,
    PROGRAM_SELECT_VALIDATION,
    PROGRAM_ID_SEPARATOR,
} from '../../Constant'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { Alert, Spinner, TextContent, Toggle } from '@amzn/awsui-components-react'
import ActiveEffectiveDateSelector, {
    updateActiveStatusForYear,
    updateEffectiveDateOnActiveChange,
} from '../reusable/ActiveEffectiveDateSelector'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import { getActiveForYearInfoMessage, getActiveInfoMessage } from '../reusable/TextUtil'

const UpdateProject = ({
    projects,
    modalMode,
    modalVisible,
    closeModalHandler,
    refreshList,
    selectedProjects,
    setSelectedProjects,
    setAlertContent,
    setAlertType,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const canAdmin = useStore((state) => state.canAdmin)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [projectID, setProjectID] = useState('')
    const [projectName, setProjectName] = useState('')
    const [projectNameError, setProjectNameError] = useState('')
    const [programNameError, setProgramNameError] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [selectedProgram, setSelectedProgram] = useState<SelectProps.Option>({
        label: 'Select a program',
        value: '',
    })
    const [isActive, setIsActive] = useState(true)
    const [isActiveForYear, setIsActiveForYear] = useState(true)
    const [originalIsActiveForYear, setOriginalIsActiveForYear] = useState(true)
    const [programList, setProgramList] = useState<object[]>([])
    const [programs, setPrograms] = useState<any[]>([])
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const DEFAULT_DATE = getDateFormat(new Date())
    const DEFAULT_YEAR = new Date().getFullYear().toString()
    const [activeStatusEffectiveDate, setActiveStatusEffectiveDate] = useState(DEFAULT_DATE)
    const [originalActivityEffectiveDate, setOriginalActivityEffectiveDate] = useState(DEFAULT_DATE)
    const [projectCreationDate, setProjectCreationDate] = useState(DEFAULT_DATE)
    const [originalIsActive, setOriginalIsActive] = useState(true)
    const [parentProgramActiveStatus, setParentProgramActiveStatus] = useState(true)
    // used for edit, instead of fetching the whole list
    const [localProgram, setLocalProgram] = useState<any>()
    const [isLocalProgramLoading, setIsLocalProgramLoading] = useState(false)

    const isReadOnly = () => {
        return !parentProgramActiveStatus && !originalIsActiveForYear
    }

    useEffect(() => {
        if (modalMode === ModalModes.EDIT) {
            if (selectedProjects === undefined || selectedProjects.length === 0) {
                return
            }
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedProjects[0])
            getLocalProgramForProject()
        } else {
            clearAllInput()
            if (modalMode === ModalModes.CREATE) {
                getAllProgramsByBusinessEntity()
            }
        }
    }, [modalMode, selectedProjects])

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getAllProgramsByBusinessEntity)
    }, [selectBusinessEntity])

    const fillAllInputWithSelectedItem = (project) => {
        setProjectID(project['project_id'])
        setSelectedProgram({
            label: project['program_name'],
            value: `${project.program_id}${PROGRAM_ID_SEPARATOR}${project.local_program_id}`,
        })
        setProjectName(project['project_name'])
        setIsActive(isProgramProjectActive(project))
        setIsActiveForYear(isProgramProjectActiveForYear(project))
        setOriginalIsActiveForYear(isProgramProjectActiveForYear(project))
        setProjectDescription(project['description'])
        setOriginalIsActive(isProgramProjectActive(project))
        const createAtDate = project['created_at'].split('T')[0]
        const defaultDate = project['is_active'] ? createAtDate : DEFAULT_DATE
        const activeEffectiveDate = project['active_status_effective_date'] || defaultDate
        setActiveStatusEffectiveDate(activeEffectiveDate)
        setProjectCreationDate(createAtDate)
        setOriginalActivityEffectiveDate(activeEffectiveDate)
        setProjectCreationDate(createAtDate)
        setOriginalActivityEffectiveDate(project['active_status_effective_date'])
        setParentProgramActiveStatus(
            'is_program_active' in project ? project['is_program_active'] === 'True' : true,
        )
    }

    const clearAllInput = () => {
        setProjectName('')
        setProjectDescription('')
        setSelectedProgram({
            label: 'Select a program',
            value: '',
        })
        setIsActive(true)
        setIsActiveForYear(true)
        setOriginalIsActive(true)
        setActiveStatusEffectiveDate(originalActivityEffectiveDate)
        setParentProgramActiveStatus(true)
        setOriginalIsActiveForYear(true)
    }

    const handleDismiss = () => {
        closeModalHandler()
        if (modalMode === ModalModes.EDIT) {
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedProjects[0])
        } else {
            clearAllInput()
        }
        setProjectNameError('')
    }

    useEffect(() => {
        setIsActiveForYear(
            updateActiveStatusForYear(
                isActive,
                activeStatusEffectiveDate,
                selectedProjects[0]?.program_project_year || DEFAULT_YEAR,
            ),
        )
    }, [activeStatusEffectiveDate, isActive])

    const getLocalProgramForProject = () => {
        if (!selectedProjects?.length) {
            console.error('No project selected. No program metadata to fetch.')
            return
        }
        const project = selectedProjects[0]
        const programId = project.program_id
        const localProgramId = project.local_program_id
        setIsLocalProgramLoading(true)
        apiClient
            .get(`/program/${programId}/${localProgramId}`)
            .then((response) => {
                setLocalProgram(response.data)
                setIsLocalProgramLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLocalProgramLoading(false)
            })
    }

    const getAllProgramsByBusinessEntity = () => {
        const year = selectedProjects[0]?.program_project_year
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${year}/programs`)
            .then((response) => {
                const allPrograms = response.data
                setPrograms(allPrograms)
                const options = [
                    ...allPrograms.map((program) => ({
                        label: program.program_name,
                        value: `${program.program_id}${PROGRAM_ID_SEPARATOR}${program.local_program_id}`,
                    })),
                ]
                setProgramList(options)
            })
            .catch((error) => console.error(error))
    }

    const handleClickUpdate = () => {
        const id = modalMode === ModalModes.EDIT ? projectID : generateId()
        const mode = modalMode === ModalModes.EDIT ? ModalModes.EDIT : ModalModes.CREATE
        const programIds = selectedProgram.value
            ? selectedProgram.value.split(PROGRAM_ID_SEPARATOR)
            : ['', '']
        const payload = {
            project_id: id,
            project_name: projectName,
            program_id: programIds[0],
            local_program_id: programIds[1],
            business_entity_id: selectBusinessEntity.id,
            is_active: isActive ? 'True' : 'False',
            active_status_effective_date: activeStatusEffectiveDate,
            description: projectDescription,
            requester: appContext.userAlias,
            program_project_year: selectedProjects[0].program_project_year,
            program_name: selectedProgram.label,
            mode,
        }

        if (!isPayloadValidated(payload)) {
            setSelectedProjects([])
            closeModalHandler()
            return
        }

        updateProject(payload)
            .then((res) => {
                refreshList()
                setAlertType(AlertTypes.SUCCESS)
                if (modalMode === ModalModes.EDIT) {
                    setAlertContent(`Successfully updated project ${projectName}.`)
                } else {
                    setAlertContent(`Successfully created project ${projectName}.`)
                }
                clearAllInput()
                setSelectedProjects([])
            })
            .catch((error) => {
                setSelectedProjects([])
                setAlertType(AlertTypes.ERROR)
                if (modalMode === ModalModes.EDIT) {
                    setAlertContent(
                        `Failed to update project ${selectedProjects[0]['project_name']}: ${error.response.data}.`,
                    )
                } else {
                    setAlertContent(
                        `Failed to create project ${projectName}: ${error.response.data}.`,
                    )
                }
                console.error(error)
            })
        closeModalHandler()
    }

    const isPayloadValidated = (payload) => {
        if (!localProgram) {
            console.error("Project's program does not exist.")
            return false
        }

        if (payload.project_name.trim().length === 0) {
            setProjectNameError(PROJECT_NAME_VALIDATION)
            return false
        }

        if (
            payload.program_id === undefined ||
            payload.local_program_id === undefined ||
            payload.program_id.trim().length === 0 ||
            payload.local_program_id.trim().length === 0
        ) {
            setProgramNameError(PROGRAM_SELECT_VALIDATION)
            return false
        }

        if (
            'is_active' in localProgram &&
            localProgram.is_active === 'False' &&
            payload.is_active === 'True'
        ) {
            setAlertType(AlertTypes.ERROR)
            setAlertContent(
                `Failed to reactivate project ${selectedProjects[0]['project_name']} because it's linked to an inactive program ${localProgram.program_name}.`,
            )
            return false
        }

        return true
    }

    const updateProject = (payload) => {
        const programIds = selectedProgram.value
            ? selectedProgram.value.split(PROGRAM_ID_SEPARATOR)
            : ['', '']
        return apiClient.post(`/program/${programIds[0]}/projects`, JSON.stringify(payload))
    }

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={modalMode === ModalModes.EDIT ? 'Edit Project' : 'Add a Project'}
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickUpdate}
                            disabled={
                                isSaveDisabled ||
                                projectNameError !== '' ||
                                isReadOnly() ||
                                isLocalProgramLoading
                            }
                        >
                            {modalMode === ModalModes.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                {isReadOnly() && (
                    <Alert
                        header={'View Only Mode'}
                    >{`The parent program, ${selectedProjects[0]?.program_name}, is inactive.`}</Alert>
                )}
                <FormField
                    label='Project'
                    description={'Name of the project'}
                    errorText={projectNameError}
                >
                    <Input
                        value={projectName}
                        readOnly={isReadOnly()}
                        onChange={({ detail }) => {
                            const value = detail.value
                            let errorMessage = ''
                            if (value.trim().length === 0) {
                                errorMessage = PROJECT_NAME_VALIDATION
                            }
                            // backend will handle duplicate check within program
                            setProjectNameError(errorMessage)
                            setProjectName(value)

                            setIsSaveDisabled(false)
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Business Entity'
                    description='Business entity that the project belongs to'
                >
                    <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                </FormField>
                <FormField
                    label='Program'
                    description='Program that the project belongs to'
                    errorText={programNameError}
                >
                    <Select
                        disabled={modalMode === ModalModes.EDIT || isReadOnly()}
                        filteringType='auto'
                        selectedOption={selectedProgram}
                        onChange={({ detail }) => {
                            setSelectedProgram(detail.selectedOption)
                            if (detail.selectedOption.value === '') {
                                setProgramNameError(PROGRAM_SELECT_VALIDATION)
                            } else {
                                setProgramNameError('')
                            }
                        }}
                        options={programList}
                    ></Select>
                    {isLocalProgramLoading && (
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            <Spinner />{' '}
                            <TextContent>
                                <p>
                                    <small>
                                        {'Loading program information... Please wait to save.'}
                                    </small>
                                </p>
                            </TextContent>
                        </SpaceBetween>
                    )}
                </FormField>
                {canAdmin && (
                    <>
                        {selectedProjects[0]?.is_program_active === 'False' && !isReadOnly() && (
                            <Alert header={'Inactive Parent Program'}>
                                Cannot activate across years, but effective date can be updated to
                                an earlier date.
                            </Alert>
                        )}
                        <SpaceBetween size={'s'} direction={'horizontal'}>
                            <FormField
                                label='Active'
                                description='Mark true if it is an active project (across years)'
                                info={
                                    <StatusInfoPopover
                                        popoverContent={getActiveInfoMessage('project')}
                                    />
                                }
                            >
                                <Toggle
                                    disabled={
                                        isReadOnly() ||
                                        selectedProjects[0]?.is_program_active === 'False'
                                    }
                                    onChange={({ detail }) => {
                                        setIsActive(detail.checked)
                                        if (modalMode === ModalModes.CREATE) {
                                            setIsSaveDisabled(false)
                                            return
                                        }
                                        setIsSaveDisabled(false)
                                        setActiveStatusEffectiveDate(
                                            updateEffectiveDateOnActiveChange(
                                                detail.checked,
                                                originalIsActive,
                                                originalActivityEffectiveDate,
                                                projectCreationDate,
                                            ),
                                        )
                                    }}
                                    checked={isActive}
                                >
                                    Is project active?
                                </Toggle>
                            </FormField>
                            <FormField
                                label={`Active For Year`}
                                description={`Marked true if it is an active project`}
                                info={
                                    <StatusInfoPopover
                                        popoverContent={getActiveForYearInfoMessage('project')}
                                    />
                                }
                            >
                                <Toggle disabled={true} checked={isActiveForYear}>
                                    {`Is project active in ${selectedProjects[0]?.program_project_year || DEFAULT_YEAR}?`}
                                </Toggle>
                            </FormField>
                        </SpaceBetween>
                        <ActiveEffectiveDateSelector
                            activeEffectiveDate={activeStatusEffectiveDate}
                            setActiveEffectiveDate={setActiveStatusEffectiveDate}
                            originalActiveEffectiveDate={originalActivityEffectiveDate}
                            isActive={isActive}
                            originalIsActive={originalIsActive}
                            checkValidChange={() => setIsSaveDisabled(false)}
                            itemType={'project'}
                            itemId={
                                selectedProjects && selectedProjects.length
                                    ? selectedProjects[0].project_id
                                    : ''
                            }
                            itemName={
                                selectedProjects && selectedProjects.length
                                    ? selectedProjects[0].project_name
                                    : 'Project'
                            }
                            itemCreationDate={projectCreationDate}
                            modalMode={isReadOnly() ? ModalModes.VIEW : modalMode}
                            blockDatesAfterDate={
                                selectedProjects[0]?.is_program_active === 'False'
                                    ? selectedProjects[0]?.program_active_status_effective_date
                                    : undefined
                            }
                        />
                    </>
                )}
                <FormField label='Description' description='Description of the project'>
                    <Textarea
                        readOnly={isReadOnly()}
                        value={projectDescription}
                        onChange={({ detail }) => {
                            setProjectDescription(detail.value)
                            if (modalMode === ModalModes.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            setIsSaveDisabled(false)
                        }}
                    ></Textarea>
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default UpdateProject

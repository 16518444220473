import React, { useState } from 'react'
import {
    Badge,
    Button,
    Pagination,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react/polaris'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import useStore from '../../Store'
import { convertToLocalTime } from '../../common/Util'
import DatePopover from '../reusable/DatePopover'
import { BADGE_COLOR, STATUS_INDICATOR_TYPES } from '../../Constant'
import { Link, PropertyFilter, StatusIndicator } from '@amzn/awsui-components-react'
import { useNavigate } from 'react-router-dom'
import {
    HISTORY_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import HistoryHeader from './HistoryHeader'

const HistoryTable = (props) => {
    const {
        isLoading,
        onHistoryLoadingChange,
        isTeamActive,
        onIsTeamActiveChange,
        isTeamSelected,
        teamId,
    } = props

    const selectedTeam = useStore((state) => state.selectedTeam)
    const selectedAllocationReportToCopy = useStore((state) => state.selectedAllocationReportToCopy)
    const setSelectedAllocationReportToCopy = useStore(
        (state) => state.setSelectedAllocationReportToCopy,
    )
    const historyAllocationReports: any[] = useStore((state) => state.historyAllocationReports)
    const setHistoryDateRange = useStore((state) => state.setHistoryDateRange)
    const setHistoryCopyAheadOverrideWeeks = useStore(
        (state) => state.setHistoryCopyAheadOverrideWeeks,
    )

    const navigate = useNavigate()

    const columnDefinitions = [
        {
            id: 'allocation_week',
            header: 'Allocation Week',
            cell: (item) => (
                <SpaceBetween size='s' direction='horizontal'>
                    <Link
                        onFollow={(event) => {
                            event.preventDefault()
                            navigate(`/allocation/${selectedTeam.value}/${item.allocation_week}`)
                        }}
                        href={`/allocation/${selectedTeam.value}/${item.allocation_week}`}
                    >
                        {item.allocation_week}
                    </Link>
                    {item.badge ? <Badge color={BADGE_COLOR.RED}>{item.badge}</Badge> : <></>}
                </SpaceBetween>
            ),
            sortingField: 'allocation_week',
        },
        {
            id: 'total_allocation',
            header: 'Allocation',
            cell: (item) => item.total_allocation || '-',
            sortingField: 'total_allocation',
        },
        {
            id: 'total_headcount',
            header: 'Headcount',
            cell: (item) => item.total_headcount || '-',
            sortingField: 'total_headcount',
        },
        {
            id: 'submitted_by',
            header: 'Submitted By',
            cell: (item) => item.submitted_by || '-',
            sortingField: 'submitted_by',
        },
        {
            id: 'submitted_at',
            header: 'Submitted At',
            cell: (item) => <DatePopover date={convertToLocalTime(item.submitted_at)} />,
            sortingField: 'submitted_at',
        },
        {
            id: 'week_closed',
            header: 'Week Closed',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.week_closed
                            ? STATUS_INDICATOR_TYPES.SUCCESS
                            : STATUS_INDICATOR_TYPES.WARNING
                    }
                >
                    {item.week_closed ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
            sortingField: 'week_closed',
        },
        {
            id: 'note',
            header: 'System Note',
            cell: (item) => item.note || '-',
        },
        {
            id: 'allocation_note',
            header: 'Allocation Note',
            cell: (item) => item.weekly_note || '-',
        },
        {
            id: 'hr_detail_note',
            header: 'HC Detail Note',
            cell: (item) => item.hr_detail_note || '-',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(historyAllocationReports, {
        propertyFiltering: {
            filteringProperties: HISTORY_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No allocation reports'
                    subtitle='No allocation reports to display.'
                    action={
                        <Button href='/allocation' iconAlign='right'>
                            Set up allocation report
                        </Button>
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            onSelectionChange={({ detail }) => {
                setSelectedAllocationReportToCopy(detail.selectedItems)
                setHistoryCopyAheadOverrideWeeks([])
                setHistoryDateRange('')
            }}
            selectedItems={selectedAllocationReportToCopy}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.allocation_week === item.allocation_week,
                    ).length
                    return `${item.allocation_week} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            columnDefinitions={columnDefinitions}
            items={items}
            loading={isLoading}
            loadingText='Loading allocation reports'
            trackBy='allocation_week'
            selectionType='single'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <HistoryHeader
                    isLoading={isLoading}
                    onHistoryLoadingChange={onHistoryLoadingChange}
                    isTeamActive={isTeamActive}
                    onIsTeamActiveChange={onIsTeamActiveChange}
                    exportData={allPageItems}
                    exportDataColumnDefinitions={columnDefinitions}
                    isTeamSelected={isTeamSelected}
                    teamId={teamId}
                />
            }
        />
    )
}

export default HistoryTable

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbGroup } from '@amzn/awsui-components-react'

const EnhancedBreadcrumbGroup = ({ items }) => {
    const navigate = useNavigate()

    return (
        <BreadcrumbGroup
            ariaLabel='Breadcrumbs'
            items={items}
            onFollow={(event) => {
                event.preventDefault()
                navigate(event.detail.item.href)
            }}
        />
    )
}

export default EnhancedBreadcrumbGroup

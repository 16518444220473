import { useAppContext } from '../../../context'
import React, { useEffect, useState } from 'react'
import {
    AlertTypes,
    EMPTY_ALLOCATION_EFFECTIVE_DATE,
    MOVE_ALL_ALLOCATIONS_DEFAULT,
    PROGRAM_ID_SEPARATOR,
} from '../../Constant'
import {
    Box,
    Button,
    DatePicker,
    FormField,
    Modal,
    Select,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react'
import { isDateEnabled } from '../reusable/AllocationUtils'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { isProgramProjectActiveForYear } from '../../common/Util'
import { scrollToTopLeft } from '../reusable/AllocationUtils'

const SELECT_PROGRAM_OPTION = {
    label: 'Select a program',
    value: '',
}

const MoveProjectsModal = ({
    modalVisible,
    closeModalHandler,
    selectedProjects,
    setSelectedProjects,
    setAlertContent,
    setAlertType,
    refreshProjects,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [allProgramOptions, setAllProgramOptions] = useState<SelectProps.Option[]>([])

    const [selectedProgram, setSelectedProgram] =
        useState<SelectProps.Option>(SELECT_PROGRAM_OPTION)
    const [isIncludeAllocationsChecked, setIsIncludeAllocationsChecked] = useState(false)
    const [copyAllHistoricAllocations, setCopyAllHistoricAllocations] = useState(
        MOVE_ALL_ALLOCATIONS_DEFAULT,
    )
    const [allocationEffectiveDate, setAllocationEffectiveDate] = useState(
        EMPTY_ALLOCATION_EFFECTIVE_DATE,
    )
    const [isMovingProjects, setIsMovingProjects] = useState<boolean>(false)

    const isDateInProgramProjectYear = (date: Date) => {
        return date.getFullYear() === new Date().getFullYear()
    }

    const handleDismiss = () => {
        closeModalHandler()
        clearAllInput()
    }

    const clearAllInput = () => {
        setSelectedProgram(SELECT_PROGRAM_OPTION)
        setIsIncludeAllocationsChecked(false)
        setCopyAllHistoricAllocations(MOVE_ALL_ALLOCATIONS_DEFAULT)
        setAllocationEffectiveDate(EMPTY_ALLOCATION_EFFECTIVE_DATE)
    }

    const generateMoveProjectPayload = () => {
        const programIds = selectedProgram.value
            ? selectedProgram.value.split(PROGRAM_ID_SEPARATOR)
            : ['', '']
        return {
            requester: appContext.userAlias,
            include_allocation: isIncludeAllocationsChecked ? 'True' : 'False',
            allocation_effective_date: copyAllHistoricAllocations
                ? EMPTY_ALLOCATION_EFFECTIVE_DATE
                : allocationEffectiveDate,
            new_program_id: programIds[0],
            new_local_program_id: programIds[1],
            new_program_name: selectedProgram.label,
            projects: selectedProjects,
        }
    }

    const getNameOfSelectedProjects = () => {
        return selectedProjects.map((prj) => prj.project_name).join(', ')
    }

    const handleMoveProjects = () => {
        setIsMovingProjects(true)
        const payload = generateMoveProjectPayload()
        apiClient
            .post('/projects/bulk-move', JSON.stringify(payload))
            .then(() => {
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(
                    `Successfully reassigned ${getNameOfSelectedProjects()} to ${
                        selectedProgram.label
                    } program.`,
                )
                refreshProjects()
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to update ${getNameOfSelectedProjects()} to ${
                        selectedProgram.label
                    } program: ${error.response.data}.`,
                )
            })
            .finally(() => {
                setSelectedProjects([])
                setSelectedProgram({
                    label: 'Select a program',
                    value: '',
                })
                setIsIncludeAllocationsChecked(false)
                setAllocationEffectiveDate(EMPTY_ALLOCATION_EFFECTIVE_DATE)
                setCopyAllHistoricAllocations(MOVE_ALL_ALLOCATIONS_DEFAULT)
                handleDismiss()
                setIsMovingProjects(false)
                scrollToTopLeft()
            })
    }

    const getAllProgramsByBusinessEntity = () => {
        const year = selectedProjects[0]?.program_project_year
        apiClient
            .get(`/business-entity/${selectedBusinessEntity.id}/year/${year}/programs`)
            .then((response) => {
                if (response.data.length) {
                    setAllProgramOptions(
                        response.data
                            .filter(
                                (prog) =>
                                    isProgramProjectActiveForYear(prog) &&
                                    prog.local_program_id !== selectedProjects[0]?.local_program_id,
                            )
                            .map((program) => {
                                return {
                                    label: program.program_name,
                                    value: `${program.program_id}${PROGRAM_ID_SEPARATOR}${program.local_program_id}`,
                                }
                            }),
                    )
                } else {
                    setAllProgramOptions([])
                    setSelectedProgram(SELECT_PROGRAM_OPTION)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity.id, getAllProgramsByBusinessEntity)
    }, [selectedBusinessEntity, selectedProjects])

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            size={'max'}
            header='Move Project(s)'
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss} disabled={isMovingProjects}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleMoveProjects}
                            // disable if program is not selected or if not copying
                            // all historic allocations but date not set
                            disabled={
                                selectedProgram.value === '' ||
                                (isIncludeAllocationsChecked &&
                                    !copyAllHistoricAllocations &&
                                    allocationEffectiveDate === EMPTY_ALLOCATION_EFFECTIVE_DATE)
                            }
                            loading={isMovingProjects}
                        >
                            Move Projects
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField label='Program' description={'Select a program '}>
                    <SpaceBetween size='s' direction='vertical'>
                        <Select
                            filteringType='auto'
                            options={allProgramOptions}
                            selectedOption={selectedProgram}
                            onChange={({ detail }) => {
                                setSelectedProgram(detail.selectedOption)
                            }}
                            selectedAriaLabel='Selected'
                            loadingText={'Loading programs'}
                            statusType={allProgramOptions.length ? 'finished' : 'loading'}
                        ></Select>
                        <Toggle
                            checked={isIncludeAllocationsChecked}
                            onChange={({ detail }) => {
                                setIsIncludeAllocationsChecked(detail.checked)
                            }}
                        >
                            Include Allocations
                        </Toggle>
                        {isIncludeAllocationsChecked && (
                            <Toggle
                                checked={copyAllHistoricAllocations}
                                onChange={({ detail }) => {
                                    setCopyAllHistoricAllocations(detail.checked)
                                }}
                            >
                                Move all allocations
                            </Toggle>
                        )}
                    </SpaceBetween>
                </FormField>
                {isIncludeAllocationsChecked && !copyAllHistoricAllocations && (
                    <FormField
                        label='Allocation Effective Date'
                        description='Select a specific week from which allocations should be moved.'
                    >
                        <SpaceBetween size='s' direction='vertical'>
                            <DatePicker
                                isDateEnabled={(date: Date) => {
                                    return isDateEnabled(date) && isDateInProgramProjectYear(date)
                                }}
                                onChange={({ detail }) => setAllocationEffectiveDate(detail.value)}
                                value={allocationEffectiveDate}
                                openCalendarAriaLabel={(selectedDate) =>
                                    'Choose Allocation Effective Date' +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : EMPTY_ALLOCATION_EFFECTIVE_DATE)
                                }
                                nextMonthAriaLabel='Next month'
                                placeholder='YYYY/MM/DD'
                                previousMonthAriaLabel='Previous month'
                                todayAriaLabel='Today'
                            />
                        </SpaceBetween>
                    </FormField>
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default MoveProjectsModal

import {
    AdvancedFilterModule,
    FiltersToolPanelModule,
    LicenseManager,
    MenuModule,
    MultiFilterModule,
    RowGroupingModule,
    SetFilterModule,
    ExcelExportModule,
    ServerSideRowModelModule,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry } from 'ag-grid-community'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-quartz.css'

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RowGroupingModule,
    MultiFilterModule,
    MenuModule,
    SetFilterModule,
    FiltersToolPanelModule,
    AdvancedFilterModule,
    ExcelExportModule,
    InfiniteRowModelModule,
    ServerSideRowModelModule,
])
LicenseManager.setLicenseKey(agGridLicense)

import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { getTotalByKeyList } from './apps/reusable/AllocationUtils'
import {
    EMPLOYEE_TYPE_GENERIC,
    EMPTY_SELECTION,
    getDefaultAllocation,
    getEmptyHrData,
    SELECT_BUSINESS_ENTITY,
} from './Constant'

let useStore: any = (set) => ({
    userTeams: [],
    selectedTeam: {},
    selectedTeamValid: true,
    allocationEndDate: '',
    allocationDateValid: true,
    allocationNoteValid: true,
    allocationTotalBIS: '0',
    allocationByEmployeeTypeTotalBIS: getDefaultAllocation(),
    expectedAllocationByEmployeeType: getDefaultAllocation(),
    expectedAllocation: '0',
    weeklyNote: '',
    hrDetailNote: '',
    allocationHeadcountValid: true,
    selectedProjectsToRemove: [],
    selectedAllocationReportToCopy: [],
    selectedProjectsToAdd: [],
    allocationProjects: [],
    allRemainProjects: [],
    allProjects: [],
    allPrograms: [],
    totalAllocationByEmployeeType: getDefaultAllocation(),
    totalAllocation: '0',
    modified: false,
    allocationPageAlert: { type: 'info', content: '' },
    allocationAddProjectAlert: { type: 'info', content: '' },
    historyDateRange: '',
    historyPageAlert: { type: 'info', content: '' },
    manageHistoryPageAlert: { type: 'info', content: '' },
    allAllocationProjects: new Map(),
    historyAllocationReports: [],
    historyCopyAheadProjects: [],
    historyCopyAheadOverrideWeeks: [],
    historyCopyAheadWizardAlert: { type: 'info', content: '' },
    businessEntities: [],
    businessEntityMap: {},
    selectBusinessEntity: {
        id: '',
        name: SELECT_BUSINESS_ENTITY,
        slack_channels: [{ slack_channel_id: '', slack_channel_name: '' }],
        slack_message_templates: [],
        configurations: { project: { non_admin_creation: false } },
        program_attributes: {
            string_params: [],
            numeric_params: [],
            dropdown_params: [],
        },
        program_attribute_order: [],
    },
    actualsYear: EMPTY_SELECTION,
    hrTeamData: {},
    hrMemberData: getEmptyHrData(),
    canAdmin: false,
    canSTL: false,
    selectedAllocationReportsToDelete: [],

    setActualsYear: (value) =>
        set((state) => ({
            ...state,
            actualsYear: value,
        })),

    setHrDetailNote: (value) =>
        set((state) => ({
            ...state,
            hrDetailNote: value,
        })),

    setHrTeamData: (value) =>
        set((state) => ({
            ...state,
            hrTeamData: value,
        })),

    setHrMemberData: (value) =>
        set((state) => ({
            ...state,
            hrMemberData: value,
        })),

    setCanAdmin: (value) =>
        set((state) => ({
            ...state,
            canAdmin: value,
        })),

    setCanSTL: (value) =>
        set((state) => ({
            ...state,
            canSTL: value,
        })),

    setBusinessEntities: (value) =>
        set((state) => ({
            ...state,
            businessEntities: value,
        })),

    setBusinessEntityMap: (value) =>
        set((state) => ({
            ...state,
            businessEntityMap: value,
        })),

    setSelectBusinessEntity: (value) =>
        set((state) => ({
            ...state,
            selectBusinessEntity: value,
        })),

    setAllocationAddProjectAlert: (value) =>
        set((state) => ({
            ...state,
            allocationAddProjectAlert: value,
        })),

    setHistoryCopyAheadWizardAlert: (value) =>
        set((state) => ({
            ...state,
            historyCopyAheadWizardAlert: value,
        })),

    setHistoryCopyAheadOverrideWeeks: (value) =>
        set((state) => ({
            ...state,
            historyCopyAheadOverrideWeeks: value,
        })),

    setHistoryAllocationReports: (value) =>
        set((state) => ({
            ...state,
            historyAllocationReports: value,
        })),

    setAllPrograms: (value) =>
        set((state) => ({
            ...state,
            allPrograms: value,
        })),

    setAllProjects: (value) =>
        set((state) => ({
            ...state,
            allProjects: value,
        })),

    setHistoryDateRange: (value) =>
        set((state) => ({
            ...state,
            historyDateRange: value,
        })),

    setHistoryCopyAheadProjects: (value) =>
        set((state) => ({
            ...state,
            historyCopyAheadProjects: value,
        })),

    setSelectedAllocationReportToCopy: (value) =>
        set((state) => ({
            ...state,
            selectedAllocationReportToCopy: value,
        })),
    setSelectedAllocationReportsToDelete: (value) =>
        set((state) => ({
            ...state,
            selectedAllocationReportsToDelete: value,
        })),
    setSelectedProjectsToRemove: (value) =>
        set((state) => ({
            ...state,
            selectedProjectsToRemove: value,
        })),

    setSelectedProjectsToAdd: (value) =>
        set((state) => ({
            ...state,
            selectedProjectsToAdd: value,
        })),

    setAllocationProjects: (value) =>
        set((state) => ({
            ...state,
            allocationProjects: value,
        })),

    setTotalAllocationByEmployeeType: (value: Object) =>
        set((state) => ({
            ...state,
            totalAllocationByEmployeeType: {
                ...state.totalAllocationByEmployeeType,
                ...value,
            },
        })),

    setTotalAllocation: (value) =>
        set((state) => ({
            ...state,
            totalAllocation: value,
        })),

    setModified: (value) =>
        set((state) => ({
            ...state,
            modified: value,
        })),

    setAllocationPageAlert: (value) =>
        set((state) => ({
            ...state,
            allocationPageAlert: value,
        })),

    setHistoryPageAlert: (value) =>
        set((state) => ({
            ...state,
            historyPageAlert: value,
        })),

    setManageHistoryPageAlert: (value) =>
        set((state) => ({
            ...state,
            manageHistoryPageAlert: value,
        })),

    setAllRemainProjects: (value) =>
        set((state) => ({
            ...state,
            allRemainProjects: value,
        })),

    setSelectedTeam: (value) =>
        set((state) => ({
            ...state,
            selectedTeam: value,
        })),

    setSelectedTeamValid: (value) =>
        set((state) => ({
            ...state,
            selectedTeamValid: value,
        })),

    setUserTeams: (value) =>
        set((state) => ({
            ...state,
            userTeams: value,
        })),

    setAllocationEndDate: (value) =>
        set((state) => {
            return {
                ...state,
                allocationEndDate: value,
            }
        }),

    setAllocationDateValid: (value) =>
        set((state) => {
            return {
                ...state,
                allocationDateValid: value,
            }
        }),

    setAllocationNoteValid: (value) =>
        set((state) => {
            return {
                ...state,
                allocationNoteValid: value,
            }
        }),

    setAllocationByEmployeeTypeTotalBIS: (value: Object) =>
        set((state) => {
            return {
                ...state,
                allocationByEmployeeTypeTotalBIS: {
                    ...state.allocationByEmployeeTypeTotalBIS,
                    ...value,
                },
            }
        }),

    setAllocationTotalBIS: (value) =>
        set((state) => {
            return {
                ...state,
                allocationTotalBIS: value,
            }
        }),

    setExpectedAllocationByEmployeeType: (value) =>
        set((state) => {
            return {
                ...state,
                expectedAllocationByEmployeeType: {
                    ...state.expectedAllocationByEmployeeType,
                    ...value,
                },
            }
        }),

    setExpectedAllocation: (value) =>
        set((state) => {
            return {
                ...state,
                expectedAllocation: value,
            }
        }),

    setWeeklyNote: (value) =>
        set((state) => {
            return {
                ...state,
                weeklyNote: value,
            }
        }),

    setAllocationHeadcountValid: (value) =>
        set((state) => {
            return {
                ...state,
                allocationHeadcountValid: value,
            }
        }),

    setAllAllocationProjects: (value) =>
        set((state) => {
            return {
                ...state,
                allAllocationProjects: value,
            }
        }),

    setLoadReportByTeam: (data) =>
        set((state) => {
            if (data.length === 0) {
                return {
                    ...state,
                    allocationProjects: [],
                    allocationByEmployeeTypeTotalBIS: getDefaultAllocation(),
                    allocationTotalBIS: '0',
                    totalAllocationByEmployeeType: getDefaultAllocation(),
                    totalAllocation: '0',
                    allocationPageAlert: {
                        content: '',
                    },
                    modified: true,
                    weeklyNote: '',
                    hrDetailNote: '',
                }
            } else {
                return {
                    ...state,
                    allocationProjects: data,
                    totalAllocationByEmployeeType: Object.fromEntries(
                        EMPLOYEE_TYPE_GENERIC.map((employeeType) => [
                            employeeType,
                            getTotalByKeyList(data, `allocation_by_employee_type.${employeeType}`),
                        ]),
                    ),
                    totalAllocation: getTotalByKeyList(data, 'allocation_value'),
                    allocationPageAlert: {
                        type: 'info',
                        content:
                            'Loaded allocation information from the report submitted on ' +
                            data[0].allocation_week +
                            '.',
                    },
                    modified: true,
                    weeklyNote: data[0].weekly_note,
                    hrDetailNote: data[0].hr_detail_note,
                }
            }
        }),
})

// TODO: remove it before production release
useStore = devtools(useStore)

export default useStore = create(useStore)

import { useState, useEffect } from 'react'
import { RowEvent } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
    IRowNode,
    IAggFuncParams,
    ColGroupDef,
} from 'ag-grid-community'
import { SpaceBetween } from '@amzn/awsui-components-react'
import { NOT_APPLICABLE } from '../../../Constant'

interface extendedColGroupDef extends ColGroupDef {
    hr_headcount?: any
}

const GRAND_TOTAL_ROW_HEIGHT = 32
const NORMAL_ROW_HEIGHT = 50
const GROUP_HEADER_ROW_HEIGHT = 75
const SUB_HEADER_ROW_HEIGHT = 30
const TOTAL_COLUMN_WIDTH = 100
const NORMAL_COLUMN_WIDTH = 50
const ADDON_COLUMN_WIDTH = 30

const TOTAL_COLOR_CODE = '#F8F6F5'
const NORMAL_CELL_COLOR_CODE = '#FFFFFF'
const DELTA_GREEN_STYLEID = 'manager-dashboard-text-style-green'
const DELTA_RED_STYLEID = 'manager-dashboard-text-style-orange'
const NULL_COLUMN_NAMES = ['employee', 'other', 'headcount', 'total']

const isInParentRow = (node: IRowNode) =>
    node.group && (node.allChildrenCount ?? 0) > 0 && !node.footer
const isInGrandTotalRow = (node: IRowNode) => node.level === -1

const isZero = (value: string) => parseFloat(value) === 0
const isNumber = (value?: string | number) => {
    return value != null && value !== '' && !isNaN(Number(value.toString()))
}
const getTwoDecimal = (value?: string | number) => {
    return isNumber(value) ? `${Number(value).toFixed(2)}` : ''
}

const customCellComponent = (props: CustomCellRendererProps) => {
    const { node, value } = props
    const [expanded, setExpanded] = useState<boolean>(node.expanded)

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)
        node.addEventListener('expandedChanged', expandListener)

        return () => {
            node.removeEventListener('expandedChanged', expandListener)
        }
    }, [])

    if (expanded && isInParentRow(node)) {
        return ''
    }
    if (isNumber(value)) {
        return getTwoDecimal(value)
    }
    return value || ''
}

export const generateTeamAllocationSummaryGridOptions = () => {
    return {
        defaultColDef: {
            cellRendererParams: {
                innerRenderer: (props: CustomCellRendererProps) => {
                    const { node, value } = props
                    if (!node.footer) {
                        return <span>{value}</span>
                    }

                    // footer: sub/grand total row
                    return isInGrandTotalRow(node) ? (
                        <SpaceBetween size='xxxs'>
                            <span>Grand Total</span>
                            <span>Expected HC</span>
                            <span>Delta HC</span>
                        </SpaceBetween>
                    ) : (
                        <span>Sub Total</span>
                    )
                },
            },
        },
        getRowStyle: ({ node }) => {
            return {
                fontWeight: node.footer ? 'bold' : 'normal',
                background: node.footer ? TOTAL_COLOR_CODE : NORMAL_CELL_COLOR_CODE,
            }
        },
        getRowHeight: ({ node }) =>
            isInGrandTotalRow(node) ? GRAND_TOTAL_ROW_HEIGHT : NORMAL_ROW_HEIGHT,
        alwaysShowHorizontalScroll: true,
        enableBrowserTooltips: true,
        groupSuppressBlankHeader: true,
        groupHeaderHeight: GROUP_HEADER_ROW_HEIGHT,
        headerHeight: SUB_HEADER_ROW_HEIGHT,
        loadingCellRenderer: 'LoadingCellRenderer',
        pagination: true,
        suppressAggFuncInHeader: true,
        suppressColumnVirtualisation: true,
        suppressRowVirtualisation: true,
    }
}

export const nullOutZeroAndDivideValuesByNumberOfWeek = (rows: any[], numberOfWeek: number) => {
    // null out zero number from the table and if the view is monthly, divide allocation by number of weeks in the month to get average monthly allocation
    return rows.map((row) => {
        const updated_row = {}
        Object.keys(row).forEach((element) => {
            if (isZero(row[element])) {
                updated_row[element] = ''
                return
            }
            if (
                numberOfWeek !== 1 &&
                NULL_COLUMN_NAMES.some((substring) => element.includes(substring))
            ) {
                updated_row[element] = String(Number(row[element]) / numberOfWeek)
                return
            }
            updated_row[element] = row[element]
        })
        return updated_row
    })
}

export const customAggSum = (params: IAggFuncParams) => {
    let sum = 0
    params.values.forEach((value) => {
        if (!value) {
            return
        }

        if (Array.isArray(value)) {
            if (!value.length) {
                return
            }
            value = value[0]
        }
        if (typeof value === 'string') {
            value = parseFloat(value)
        }
        sum += value
    })

    if (!isInGrandTotalRow(params.rowNode) || params.colDef.headerName === 'Total') {
        return sum ? getTwoDecimal(sum) : ''
    }

    // Last row -> Grand Total/Expected HC/Delta HC
    const colGroupDef = params.column.getOriginalParent()?.getColGroupDef() as extendedColGroupDef
    const hrHeadcount = colGroupDef?.hr_headcount

    let expectedHC = NOT_APPLICABLE
    let delta = '0.00'
    let styleId = ''

    if (hrHeadcount) {
        if (params.colDef.headerName === 'Employee') {
            expectedHC = getTwoDecimal(hrHeadcount.employee)
        } else {
            expectedHC = getTwoDecimal(hrHeadcount.other)
        }
        delta = getTwoDecimal(sum - +expectedHC)
    }

    if (expectedHC === NOT_APPLICABLE) {
        delta = NOT_APPLICABLE
    } else {
        styleId = isZero(delta) ? DELTA_GREEN_STYLEID : DELTA_RED_STYLEID
    }

    return (
        <SpaceBetween size='xxxs'>
            <span>{getTwoDecimal(sum)}</span>
            <span>{expectedHC}</span>
            <span id={styleId}>{delta}</span>
        </SpaceBetween>
    )
}

export const getFormatFixedColumns = (
    cols: any[],
    customHeaderGroup: any,
    customHeaderGroupParams: any,
) => {
    const result = [...cols]

    result[0] = {
        ...result[0],
        rowGroup: true,
        hide: true,
    }
    result[1] = {
        ...result[1],
        pinned: 'left',
    }
    result[2] = {
        ...result[2],
        aggFunc: customAggSum,
        cellRenderer: customCellComponent,
        width: TOTAL_COLUMN_WIDTH,
    }

    for (let idx = 3; idx < result.length; idx++) {
        result[idx].children = result[idx].children.map((child) => {
            return {
                ...child,
                aggFunc: customAggSum,
                cellRenderer: customCellComponent,
                autoHeight: true,
                width: (child.width || NORMAL_COLUMN_WIDTH) + ADDON_COLUMN_WIDTH,
            }
        })
        result[idx].headerGroupComponent = customHeaderGroup
        result[idx].headerGroupComponentParams = {
            ...customHeaderGroupParams,
            teamColumn: result[idx],
        }
    }

    return result
}

export const is_object_has_target = (obj, target) => {
    for (const key of Object.keys(obj)) {
        if (obj[key] === target) {
            return true
        }
    }
    return false
}

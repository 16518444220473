import { Alert, Link, SpaceBetween } from '@amzn/awsui-components-react'
import React from 'react'
import {
    ADMIN_ACCESS_REQUEST,
    CREATE_TEAM_REQUEST,
    FALCON_SLACK_CHANNEL,
    SLACK_AMAZON_OPERATIONS,
} from '../../common/LinkUtil'
import { PERMISSION_GROUP_PREFIX } from '../../Constant'
import { useNavigate } from 'react-router-dom'
import StatusInfoPopover from './StatusInfoPopover'

export const LIMITED_ACCESS_MESSAGE = (props) => {
    const { item, link, slackId, slackName } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Read only mode for non-admin user. Contact
            <Link
                external
                externalIconAriaLabel='Opens in a new tab'
                href={slackId ? SLACK_AMAZON_OPERATIONS + slackId : FALCON_SLACK_CHANNEL}
            >
                #{slackName ? slackName : 'falcon-resource-allocation-help-and-requests'}
            </Link>
            {`for more information, request new ${item} `}
            <Link external externalIconAriaLabel='Opens in a new tab' href={link}>
                here
            </Link>
            , or request admin access
            <Link external externalIconAriaLabel='Opens in a new tab' href={ADMIN_ACCESS_REQUEST}>
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const ALERT_TEMPLATE = (props) => {
    const { header, text, type, dismissible, onDismiss } = props
    return (
        <Alert
            header={header}
            type={type}
            dismissible={dismissible}
            onDismiss={() => onDismiss({ content: '' })}
        >
            <SpaceBetween direction='horizontal' size='xxs'>
                {text}
            </SpaceBetween>
        </Alert>
    )
}

export const NO_PERMISSION_MESSAGE = (teamData) => {
    const { team } = teamData
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            {team
                ? `No permission to access the allocation report under ${team.team_name}. Contact`
                : 'No permission to access the business entity. Contact'}
            <SLACK_CHANNEL_TEXT />
            for more information or to request permission.
        </SpaceBetween>
    )
}

export const INACTIVE_TEAM_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Read only mode for inactive team. Contact
            <SLACK_CHANNEL_TEXT />
            for more information or to request re-active the team.
        </SpaceBetween>
    )
}

export const CLOSED_WEEK_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Read only mode for closed week. Contact
            <SLACK_CHANNEL_TEXT />
            for more information or to request re-opening the closed week.
        </SpaceBetween>
    )
}

export const INACTIVE_TEAM_AND_CLOSED_WEEK_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Read only mode for inactive team and closed week. Contact
            <SLACK_CHANNEL_TEXT />
            for more information or to request re-active the team and re-opening the closed week.
        </SpaceBetween>
    )
}

export const NO_TEAM_MESSAGE = () => {
    const navigate = useNavigate()
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            No registered teams found. Set up team
            <Link
                onFollow={() => {
                    navigate('/myteam')
                }}
            >
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const UNREGISTERED_TEAM_MESSAGE = (teamData) => {
    const navigate = useNavigate()
    const { team } = teamData
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            {`Cannot access the allocation report. Set up
      team ${team.team_name} (under organization ${team.org_name}) `}
            <Link
                onFollow={() => {
                    navigate('/myteam')
                }}
            >
                here
            </Link>{' '}
            or contact
            <SLACK_CHANNEL_TEXT />
            for more information.
        </SpaceBetween>
    )
}

export const NON_EXISTING_TEAM_ID_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Contact
            <SLACK_CHANNEL_TEXT />
            for more information or request new team
            <Link external externalIconAriaLabel='Opens in a new tab' href={CREATE_TEAM_REQUEST}>
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const NON_EXISTING_HR_PERMISSION_GROUP_ID_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Expected / Delta headcount data not available because no HR permission group info in the
            team. Contact
            <SLACK_CHANNEL_TEXT />
            for more information or request add HR permission group info
            <Link external externalIconAriaLabel='Opens in a new tab' href={CREATE_TEAM_REQUEST}>
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const HR_DATA_MESSAGE = (props) => {
    const { hrTeam } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Headcount detail resource for permission group
            <Link
                external
                externalIconAriaLabel='Opens in a new tab'
                href={PERMISSION_GROUP_PREFIX + hrTeam.hr_permission_group}
            >
                {hrTeam.hr_permission_group_name}
            </Link>
            . Contact
            <SLACK_CHANNEL_TEXT />
            for more information.
        </SpaceBetween>
    )
}

export const NON_HR_DATA_MESSAGE = () => {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Cannot find HR headcount data. Contact
            <SLACK_CHANNEL_TEXT />
            for more information
            <Link external externalIconAriaLabel='Opens in a new tab' href={CREATE_TEAM_REQUEST}>
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const SLACK_CHANNEL_TEXT = () => {
    return (
        <Link external externalIconAriaLabel='Opens in a new tab' href={FALCON_SLACK_CHANNEL}>
            #falcon-resource-allocation-help-and-requests
        </Link>
    )
}

export function contactSlackMessage(message: string) {
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            {message} Contact
            <SLACK_CHANNEL_TEXT />
            for more information.
        </SpaceBetween>
    )
}

export const EMPTY_SELECTION = 'Select Option'
export const getActiveForYearInfoMessage = (itemType) => {
    return `Active For Year is True only if the ${itemType} is active for the entire year (either Is Active is True or the effective year is after the ${itemType}'s year).`
}

export const getActiveInfoMessage = (itemType) => {
    return `A ${itemType} is Active only if it is active in every Actuals year it is added to.`
}

import { FormField, Select, Spinner } from '@amzn/awsui-components-react'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context'
import { PROGRAM_ID_SEPARATOR, PROGRAM_SELECT_VALIDATION } from '../../Constant'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { isProgramProjectActiveForYear } from '../../common/Util'
import { useNavigate, useLocation } from 'react-router-dom'

const CreateProjectTableProgramName = (props) => {
    const {
        id,
        selectedProgram,
        selectedProgramError,
        onTeamSelectedProgramChange,
        isProgramPreSelected,
        selectedYear,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [programOptions, setProgramOptions] = useState<any>([])
    const navigate = useNavigate()
    const [previousSelectedYear, setPreviousSelectedYear] = useState<string>(selectedYear)
    const location = useLocation()
    const getAllProgramsByBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${selectedYear}/programs`)
            .then((response) => {
                const allPrograms = response.data
                if (!allPrograms.length) {
                    setProgramOptions([
                        {
                            label: `No Programs Found for Year ${selectedYear}`,
                            value: '',
                        },
                    ])
                    return
                }
                const options = [
                    ...allPrograms
                        .filter((program) => isProgramProjectActiveForYear(program))
                        .map((program) => {
                            return {
                                label: program.program_name,
                                value: `${program.program_id}${PROGRAM_ID_SEPARATOR}${program.local_program_id}`,
                            }
                        }),
                ]
                setProgramOptions(options)
            })
            .catch((error) => console.error(error))
    }

    useEffect(() => {
        if (isProgramPreSelected) {
            setProgramOptions([selectedProgram])
        }
    }, [selectedProgram])

    useEffect(() => {
        setPreviousSelectedYear(selectedYear)
    }, [programOptions])

    useEffect(() => {
        if (!isProgramPreSelected && selectedYear) {
            BusinessEntityRefresh(selectBusinessEntity.id, getAllProgramsByBusinessEntity)
            return
        }
        if (
            localStorage.getItem('selectedProgramBusinessEntity') !== selectBusinessEntity.id &&
            (location.pathname.includes('program') || location.pathname.includes('project'))
        ) {
            navigate(location.pathname)
            return
        }
        setProgramOptions([selectedProgram])
    }, [selectBusinessEntity, selectedYear])

    return (
        (programOptions.length && selectedYear === previousSelectedYear && (
            <FormField errorText={selectedProgramError}>
                <Select
                    selectedOption={selectedProgram}
                    filteringType='auto'
                    onChange={({ detail }) => {
                        let errorMessage = ''
                        if (detail.selectedOption.value === '') {
                            errorMessage = PROGRAM_SELECT_VALIDATION
                        }
                        onTeamSelectedProgramChange(id, 'selectedProgramError', errorMessage)
                        onTeamSelectedProgramChange(id, 'selectedProgram', detail.selectedOption)
                    }}
                    options={programOptions}
                    expandToViewport
                    onBlur={() => {
                        if (selectedProgram.value === '') {
                            onTeamSelectedProgramChange(
                                id,
                                'selectedProgramError',
                                PROGRAM_SELECT_VALIDATION,
                            )
                        }
                    }}
                />
            </FormField>
        )) || <Spinner />
    )
}

export default CreateProjectTableProgramName

import React, { useEffect, useReducer, useState } from 'react'
import ProgramList from './ProgramList'
import CreateProgram from './CreateProgram'
import { useAppContext } from '../../../context'
import { Alert, Box, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react'
import { formatYearSelection, getDateFormat } from '../../common/Util'
import {
    AlertTypes,
    EMPTY_SELECTION,
    EMPTY_YEAR_SELECTION,
    GLOBAL_BUSINESS_ENTITY,
    ModalModes,
    SELECT_BUSINESS_ENTITY,
} from '../../Constant'
import { CREATE_PROGRAM_REQUEST } from '../../common/LinkUtil'
import useStore from '../../Store'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { LIMITED_ACCESS_MESSAGE } from '../reusable/TextUtil'
import DeleteModal from '../reusable/DeleteModal'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import {
    getCoreProgramColumnDefinitions,
    getCoreProgramTableVisibleColumns,
    getProgramColumnDefinitions,
    getProgramTableVisibleColumns,
    getFormattedAttributes,
    PROGRAM_SELECTION_IDS,
    programAttributes,
} from './ProgramAttributes'
import { useNavigate } from 'react-router-dom'
import { generateInitialState } from './ProgramConstants'
import {
    convertBoolStringToBool,
    decompressResponse,
    loadSelectedState,
    REDUCER_ACTIONS,
} from '../reusable/Utils'
import {
    updateActiveStatusForYear,
    updateEffectiveDateOnActiveChange,
} from '../reusable/ActiveEffectiveDateSelector'
import { ProgramContextType, defaultState, ProgramContext } from './ProgramContext'
import { formatBusinessEntities, formatOPYears, getAllProjectsUnderProgram } from './ProgramUtil'

const SetupProgram = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const canAdmin = useStore((state) => state.canAdmin)
    const canSTL = useStore((state) => state.canSTL)
    const programs = useStore((state) => state.allPrograms)
    const setPrograms = useStore((state) => state.setAllPrograms)
    const navigate = useNavigate()

    const businessEntityMap = useStore((state) => state.businessEntityMap)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [selectedBusinessEntityFilter, setSelectedBusinessEntityFilter] = useState({
        label: '',
        value: '',
    })
    const [selectedYearFilter, setSelectedYearFilter] = useState(EMPTY_SELECTION)
    const [yearFilterOptions, setYearFilterOptions] = useState<any>([EMPTY_SELECTION])
    const [businessEntityFilterOptions, setBusinessEntityFilterOptions] = useState([
        {
            label: 'All Programs',
            value: GLOBAL_BUSINESS_ENTITY,
        },
        ...(selectBusinessEntity.id !== SELECT_BUSINESS_ENTITY
            ? [
                  {
                      label: selectBusinessEntity.name,
                      value: selectBusinessEntity.id,
                  },
              ]
            : []),
    ])
    const [userPrograms, setUserPrograms] = useState<any>([])
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<ModalModes>(ModalModes.NOT_SET)
    const [selectedPrograms, setSelectedPrograms] = useState<any>([])
    const [isProgramLoading, setIsProgramLoading] = useState(true)
    const [deleteModalHeader, setDeleteModalHeader] = useState('')
    const [deleteModalButton, setDeleteModalButton] = useState('')
    const [deleteModalDescription, setDeleteModalDescription] = useState('')
    const [alertItems, setAlertItems] = useState<any[]>([])
    const [initialKingpinGoals, setInitialKingpinGoals] = useState<any[]>([])
    const [initialImportantLinks, setInitialImportantLinks] = useState<any[]>([])
    const [initialActiveEffectiveDate, setInitialActiveEffectiveDate] = useState('')
    const [isAddingProgram, setIsAddingProgram] = useState(false)
    const [showActivePrograms, setShowActivePrograms] = useState(false)
    const [visiblePrograms, setVisiblePrograms] = useState([])
    const [isDataUnmodified, setIsDataUnmodified] = useState<boolean>(true)
    const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false)
    const [isModalExpanded, setIsModalExpanded] = useState<boolean>(false)
    const [initialIsActive, setInitialIsActive] = useState(true)
    const [globalAttributesError, setGlobalAttributesError] = useState('')
    const [isLoadingParentProgramPlans, setIsLoadingParentProgramPlans] = useState(false)
    const [isLoadingParentProgramGlobalAttrs, setIsLoadingParentProgramGlobalAttrs] =
        useState(false)
    const [parentProgramAllGlobalAttributes, setParentProgramAllGlobalAttributes] = useState<any>(
        [],
    )
    const [parentProgramAllPlans, setParentProgramAllPlans] = useState<string[]>([])

    const getUserPrograms = () => {
        const userAlias = appContext.userAlias
        apiClient
            .get(`/user-roles/${userAlias}`)
            .then((res) => {
                setUserPrograms(res.data[selectBusinessEntity.id]['USER_PROGRAMS'])
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const generateProgramOptions = () => {
        return selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY || !showActivePrograms
            ? [...programs]
            : programs.filter((prog) => prog.is_active_for_year)
    }

    useEffect(() => {
        if (!canAdmin) {
            setVisiblePrograms(programs)
            return
        }
        setVisiblePrograms(generateProgramOptions())
    }, [programs, showActivePrograms])

    const fetchAllGlobalAttributesForParentProgram = () => {
        const parentProgramId = selectedPrograms[0].parent_program_id
        setIsLoadingParentProgramPlans(true)
        apiClient
            .get(`/parent-program/${parentProgramId}/plans`)
            .then((res) => {
                const plansAndAttrs = res.data
                setParentProgramAllPlans(plansAndAttrs['plans'])
                const globalAttrs = plansAndAttrs['parent_program_attributes'].map(
                    (globalAttr) => ({
                        ...globalAttr,
                        stl_alias: globalAttr['stl_alias'] ? [globalAttr['stl_alias']] : [],
                        registered_users: globalAttr['registered_users'] || [],
                    }),
                )
                setParentProgramAllGlobalAttributes(globalAttrs)
                setIsLoadingParentProgramPlans(false)
            })
            .catch((err) => {
                console.error(err)
                setParentProgramAllPlans([])
                setParentProgramAllGlobalAttributes([])
                setIsLoadingParentProgramPlans(false)
            })
    }

    const fetchGlobalAttributesForParentProgramAndYear = (actualsYear) => {
        const parentProgramId = selectedPrograms[0].parent_program_id
        apiClient
            .get(`/parent-program/${parentProgramId}/actuals/${actualsYear}`)
            .then((res) => {
                setGlobalAttributesError('')
                const program = res.data
                const programWithGlobalAttrs = {
                    ...program,
                    stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                    registered_users: program['registered_users']
                        ? program['registered_users']
                        : [],
                }
                loadSelectedProgram(programWithGlobalAttrs, isAddingProgram)
                setIsLoadingParentProgramGlobalAttrs(false)
            })
            .catch((err) => {
                setGlobalAttributesError(err.response.data)
                console.error(err.response.data)
                setIsLoadingParentProgramGlobalAttrs(false)
                loadSelectedProgram(selectedPrograms[0], isAddingProgram)
            })
    }

    // todo: uncomment after export with detail is implemented
    // useEffect(() => {
    //     setIsProjectsLoading(true)
    //     getAllProjectsUnderProgram(programs, apiClient, setProjects, setIsProjectsLoading)
    //     setProgramContext({ ...programContext, programs: visiblePrograms })
    // }, [programs])
    //
    // useEffect(() => {
    //     if (!isProjectsLoading) {
    //         setProgramContext({ ...programContext, projects: projects })
    //     }
    // }, [isProjectsLoading])

    useEffect(() => {
        setSelectedYearFilter(
            yearFilterOptions[0] === EMPTY_YEAR_SELECTION
                ? EMPTY_YEAR_SELECTION
                : yearFilterOptions[0],
        )
    }, [yearFilterOptions])

    const getYearsInBusinessEntity = () => {
        apiClient
            .get(`/years/${selectBusinessEntity.id}`)
            .then((res) => {
                const formattedYears = formatYearSelection(res.data)
                setYearFilterOptions(
                    formattedYears.length ? formattedYears : [EMPTY_YEAR_SELECTION],
                )
            })
            .catch((err) => {
                console.error(err)
                setYearFilterOptions([EMPTY_YEAR_SELECTION])
            })
    }

    useEffect(() => {
        // ensure we de-select items that should have been filtered out
        if (!selectedPrograms || !selectedPrograms.length) {
            return
        }
        setSelectedPrograms(
            selectedPrograms.filter(
                (prog) => !(showActivePrograms && prog.is_active_for_year !== 'True'),
            ),
        )
    }, [showActivePrograms])

    const isModalDefaultExpanded = () => {
        const businessEntityDropdownVal = selectedBusinessEntityFilter.value
        return (
            businessEntityDropdownVal === selectBusinessEntity.id ||
            (businessEntityDropdownVal === GLOBAL_BUSINESS_ENTITY && isAddingProgram) ||
            !canAdmin
        )
    }

    const handleCreate = () => {
        setModalMode(ModalModes.CREATE)
        setIsModalExpanded(isModalDefaultExpanded())
        setCreateModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(ModalModes.EDIT)
        loadSelectedProgram(selectedPrograms[0], false)
        setCreateModalVisible(true)
        setIsModalExpanded(isModalDefaultExpanded())
    }

    const loadSelectedItemState = (state, selectedItem) => {
        const isViewOnly = isAddingProgram || (!canAdmin && !canSTL)
        const result = loadSelectedState(state, selectedItem, isViewOnly)
        setInitialImportantLinks(
            selectedItem?.important_links && selectedItem.important_links.length
                ? [...selectedItem.important_links]
                : [],
        )
        setInitialKingpinGoals(
            selectedItem.kingpin_goals && selectedItem.kingpin_goals.length
                ? [...selectedItem.kingpin_goals]
                : [],
        )
        setInitialIsActive(selectedItem?.is_active ?? true)
        setInitialActiveEffectiveDate(
            (selectedItem?.active_status_effective_date || '').split('T')[0] ??
                getDateFormat(new Date()),
        )
        return result
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case REDUCER_ACTIONS.input:
                const inpChange = {
                    ...state,
                    [action.target]: action.value,
                }
                if (action.target === 'is_active') {
                    const newEffectiveDate = updateEffectiveDateOnActiveChange(
                        action.value,
                        initialIsActive,
                        initialActiveEffectiveDate,
                        modalMode === ModalModes.CREATE
                            ? getDateFormat(new Date())
                            : (selectedPrograms[0].created_at || '').split('T')[0],
                    )
                    return {
                        ...inpChange,
                        ['active_status_effective_date']: newEffectiveDate,
                        ['is_active_for_year']: updateActiveStatusForYear(
                            action.value,
                            newEffectiveDate,
                            selectedPrograms[0].program_project_year,
                        ),
                    }
                } else if (action.target === 'active_status_effective_date') {
                    return {
                        ...inpChange,
                        ['is_active_for_year']: updateActiveStatusForYear(
                            state.is_active,
                            action.value,
                            selectedPrograms[0].program_project_year,
                        ),
                    }
                }
                return inpChange
            case REDUCER_ACTIONS.initial:
                return initialState
            case REDUCER_ACTIONS.load:
                return loadSelectedItemState(state, action.target)
            default:
                return state
        }
    }
    const initialState = generateInitialState(programAttributes)

    const [state, dispatch] = useReducer(reducer, initialState)

    const inputChange = (target: string, value: string) => {
        dispatch({ type: REDUCER_ACTIONS.input, target: target, value: value })
    }

    const clearAllInputs = () => {
        dispatch({ type: REDUCER_ACTIONS.initial })
    }

    const loadSelectedProgram = (target, isAddingProgram: boolean) => {
        dispatch({
            type: REDUCER_ACTIONS.load,
            target: target,
            isAddingProgram: isAddingProgram,
        })
    }

    useEffect(() => {
        const viewingAllPrograms = selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
        const headerOverride = viewingAllPrograms
            ? 'Delete Global Programs'
            : `Remove From ${selectBusinessEntity.name}`
        const descriptionOverride = viewingAllPrograms
            ? 'Delete permanently? Admins will no longer be able to add this program to their business entities.'
            : `Remove programs from ${selectBusinessEntity.name}?`
        const buttonTextOverride = viewingAllPrograms ? 'Delete' : 'Remove'
        setDeleteModalHeader(headerOverride)
        setDeleteModalDescription(descriptionOverride)
        setDeleteModalButton(buttonTextOverride)
    }, [selectedBusinessEntityFilter])

    const handleAddAlertContent = (message: string, alertType: any) => {
        const alertId = `${appContext.userAlias}-${Date.now()}`
        const newAlert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== alertId))
            },
            dismissible: true,
            content: message,
            type: alertType,
            id: alertId,
        }
        setAlertItems([newAlert, ...alertItems])
    }
    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCreateModalClose = () => {
        setSelectedPrograms([])
        setCreateModalVisible(false)
        setIsAddingProgram(false)
        setIsDataUnmodified(true)
    }

    const onAllProgramsViewNameClick = (programItem) => {
        setModalMode(ModalModes.VIEW)
        loadSelectedProgram(programItem, false)
        setCreateModalVisible(true)
    }
    const [columnDefinitions, setColumnDefinitions] = useState<any[]>(
        getProgramColumnDefinitions(navigate, programAttributes),
    )
    const [coreColumnDefinitions, setCoreColumnDefinitions] = useState<any[]>(
        getCoreProgramColumnDefinitions(onAllProgramsViewNameClick, programAttributes),
    )
    const coreVisibleColumns = getCoreProgramTableVisibleColumns()
    const visibleColumns = getProgramTableVisibleColumns()

    const deletePrograms = () => {
        const program_ids = selectedPrograms.map((program) => program['program_id']).join(',')
        const local_program_id_key =
            selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                ? 'program_id'
                : 'local_program_id'
        const local_program_ids = selectedPrograms
            .map((program) => program[local_program_id_key])
            .join(',')
        setDeleteModalVisible(false)
        setIsProgramLoading(true)
        apiClient
            .delete(`/programs?program_ids=${program_ids}&local_program_ids=${local_program_ids}`)
            .then(() => {
                getYearsInBusinessEntity()
                handleAddAlertContent(
                    `Successfully ${
                        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                            ? 'deleted '
                            : 'removed '
                    } ${selectedPrograms.map((pro) => pro.program_name)}${
                        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                            ? '.'
                            : ` from ${selectBusinessEntity.name}.`
                    }`,
                    AlertTypes.SUCCESS,
                )
                setSelectedPrograms([])
            })
            .catch((error) => {
                handleAddAlertContent(
                    `Failed to delete program ${selectedPrograms.map((pro) => pro.program_name)}: ${
                        error?.response?.data
                    }.`,
                    AlertTypes.ERROR,
                )
                setSelectedPrograms([])
                console.error(error)
                setIsProgramLoading(false)
            })
        setIsProgramLoading(false)
    }

    const getAllPrograms = () => {
        setIsProgramLoading(true)
        let urlPrograms = `/programs?metadata=true`
        const useCorePrograms = selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
        if (!useCorePrograms) {
            urlPrograms = `/business-entity/${selectBusinessEntity.id}/year/${selectedYearFilter?.value}/programs`
        }
        apiClient
            .get(urlPrograms)
            .then((response) => {
                let allPrograms = response.data
                if (useCorePrograms) {
                    allPrograms = decompressResponse(response.data)
                }
                allPrograms = allPrograms.map((prog) => ({
                    ...prog,

                    stl_alias: prog['stl_alias'] ? [prog['stl_alias']] : [],
                    registered_users: prog['registered_users'] ? prog['registered_users'] : [],
                }))
                // convert BE names to list and sort
                if (useCorePrograms) {
                    allPrograms = allPrograms.map((programData) => ({
                        ...programData,
                        ['business_entity_name']: formatBusinessEntities(
                            programData.business_entity_name,
                        ),
                        ['program_id']: programData.parent_program_id,
                    }))
                } else {
                    allPrograms = allPrograms.map((prog) => ({
                        ...prog,
                        ['is_active']: convertBoolStringToBool(prog.is_active),
                        ['is_active_for_year']: convertBoolStringToBool(prog.is_active_for_year),
                        ['is_op_goal']: convertBoolStringToBool(prog.is_op_goal),
                    }))
                    if (!canAdmin) {
                        allPrograms = allPrograms.filter((prog) => prog.is_active_for_year)
                    }
                }
                setPrograms(allPrograms)
                setIsProgramLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setPrograms([])
                setIsProgramLoading(false)
            })
    }

    useEffect(() => {
        setYearFilterOptions([EMPTY_SELECTION])
        setSelectedYearFilter(EMPTY_SELECTION)
        setIsProgramLoading(true)
        setBusinessEntityFilterOptions([
            {
                label: 'All Programs',
                value: GLOBAL_BUSINESS_ENTITY,
            },
            ...(selectBusinessEntity.id.trim().length
                ? [
                      {
                          label: selectBusinessEntity.name,
                          value: selectBusinessEntity.id,
                      },
                  ]
                : []),
        ])
        if (!selectBusinessEntity.id.trim()) {
            return
        } else {
            setSelectedBusinessEntityFilter({
                label: selectBusinessEntity.name,
                value: selectBusinessEntity.id,
            })
        }
        getYearsInBusinessEntity()
        getUserPrograms()
        apiClient
            .get(`/egret/settings?keys=${PROGRAM_SELECTION_IDS.join(',')}`)
            .then((res) => {
                const selections = res.data
                const formattedAttributes = getFormattedAttributes(selections, programAttributes)
                setColumnDefinitions(getProgramColumnDefinitions(navigate, formattedAttributes))
                setCoreColumnDefinitions(
                    getCoreProgramColumnDefinitions(
                        onAllProgramsViewNameClick,
                        formattedAttributes,
                    ),
                )
            })
            .catch((err) => {
                console.error(err)
            })
    }, [selectBusinessEntity])

    useEffect(() => {
        if (!selectBusinessEntity.id) {
            setIsProgramLoading(false)
        }
        if (selectedYearFilter?.value) {
            BusinessEntityRefresh(selectBusinessEntity.id, getAllPrograms)
        } else {
            if (selectedYearFilter?.value === 'no_year_found') {
                setPrograms([])
                setIsProgramLoading(false)
            }
        }
        setSelectedPrograms([])
        setCreateModalVisible(false)
        setDeleteModalVisible(false)
    }, [selectedBusinessEntityFilter, selectedYearFilter])

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Programs', href: '' },
                            ]}
                        />
                        {canAdmin ? (
                            <></>
                        ) : (
                            <Alert header='Limited Access'>
                                <LIMITED_ACCESS_MESSAGE
                                    item='program'
                                    link={CREATE_PROGRAM_REQUEST}
                                />
                            </Alert>
                        )}
                        {!alertItems.length ? (
                            <></>
                        ) : (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                titleOverride={deleteModalHeader}
                descriptionOverride={deleteModalDescription}
                buttonOverride={deleteModalButton}
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deletePrograms}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <ProgramList
                    canAdmin={canAdmin}
                    canSTL={canSTL}
                    userPrograms={userPrograms}
                    programs={visiblePrograms}
                    selectedPrograms={selectedPrograms}
                    setSelectedPrograms={setSelectedPrograms}
                    selectedBusinessEntityFilter={selectedBusinessEntityFilter}
                    setSelectedBusinessEntityFilter={setSelectedBusinessEntityFilter}
                    businessEntityFilterOptions={businessEntityFilterOptions}
                    selectedYearFilter={selectedYearFilter}
                    setSelectedYearFilter={setSelectedYearFilter}
                    yearFilterOptions={yearFilterOptions}
                    columns={
                        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                            ? coreColumnDefinitions
                            : columnDefinitions
                    }
                    visibleColumns={
                        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                            ? coreVisibleColumns
                            : visibleColumns
                    }
                    onCreate={handleCreate}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isLoading={isProgramLoading}
                    setShowActivePrograms={setShowActivePrograms}
                    showActivePrograms={showActivePrograms}
                    onIsAddingProgramChange={setIsAddingProgram}
                    loadSelectedProgram={loadSelectedProgram}
                    onView={onAllProgramsViewNameClick}
                    fetchAllPlansAndGlobalAttributes={fetchAllGlobalAttributesForParentProgram}
                    setIsLoadingAllPlansGlobalAttributes={setIsLoadingParentProgramPlans}
                    fetchGlobalAttributesForActualsYear={
                        fetchGlobalAttributesForParentProgramAndYear
                    }
                />
            </Box>
            <CreateProgram
                setIsProgramLoading={setIsProgramLoading}
                visible={createModalVisible}
                modalMode={canAdmin || canSTL ? modalMode : ModalModes.VIEW}
                setModalMode={setModalMode}
                closeModalHandler={handleCreateModalClose}
                refreshList={getYearsInBusinessEntity}
                handleAddAlertContent={handleAddAlertContent}
                clearAllInputs={clearAllInputs}
                selectedBusinessEntityFilter={selectedBusinessEntityFilter}
                isInputInvalid={isInputInvalid}
                onIsInputInvalidChanged={setIsInputInvalid}
                selectedPrograms={selectedPrograms}
                programs={visiblePrograms}
                state={state}
                initialKingpinGoals={initialKingpinGoals}
                initialImportantLinks={initialImportantLinks}
                initialActiveEffectiveDate={initialActiveEffectiveDate}
                isAddingProgram={isAddingProgram}
                inputChange={inputChange}
                isDataUnmodified={isDataUnmodified}
                onIsDataUnmodifiedChanged={setIsDataUnmodified}
                isModalExpanded={isModalExpanded}
                onIsModalExpandedChanged={setIsModalExpanded}
                requester={appContext.userAlias || ''}
                initialIsActive={initialIsActive}
                globalAttributesError={globalAttributesError}
                setGlobalAttributesError={setGlobalAttributesError}
                isLoadingGlobalAttrs={isLoadingParentProgramGlobalAttrs}
                isLoadingParentProgramPlans={isLoadingParentProgramPlans}
                allParentProgramPlans={parentProgramAllPlans}
                loadSelectProgram={loadSelectedProgram}
                allParentProgramGlobalAttributes={parentProgramAllGlobalAttributes}
                fetchGlobalAttributesForParentProgramAndYear={
                    fetchGlobalAttributesForParentProgramAndYear
                }
            />
        </ContentLayout>
    )
}

export default SetupProgram

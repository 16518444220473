import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Flashbar,
    Modal,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../../context'
import BulkCreateProjectTable from './BulkCreateProjectTable'
import {
    NewProject,
    createNewProject,
    getNewProjectAdd,
    getNewProjectDelete,
    getNewProjectChange,
    createNewProjectUnderProgram,
} from './projectUtil'
import useStore from '../../Store'
import { ModalModes, PROGRAM_ID_SEPARATOR } from '../../Constant'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    PROJECT_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import EmptyState from '../reusable/EmptyState'
import { Pagination, PropertyFilter, Select } from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../reusable/AllocationUtils'

const BulkCreateImportProject = (props) => {
    const {
        projects,
        projectsInBE,
        columns,
        bulkModalVisible,
        onBulkModalDismiss,
        onProjectLoadingChange,
        onProjectsChange,
        setAlertContent,
        setAlertType,
        isProgramPreSelected,
        isProgramLoading,
        preSelectedProgram,
        programMetadata,
        yearFilterOptions,
        selectedYearFilter,
        setSelectedYearFilter,
        isLoading,
        mode,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const createNewProjectBulkTable = () => {
        return isProgramPreSelected
            ? createNewProjectUnderProgram(preSelectedProgram)
            : createNewProject()
    }

    const [alertItems, setAlertItems] = useState<object[]>([])
    const [preferences] = useState({ pageSize: 20 })
    const [selectedImportProjects, setSelectedImportProjects] = useState<any>([])
    const [newProjects, setNewProjects] = useState<NewProject[]>([createNewProjectBulkTable()])
    const [visibleExistingProjects, setVisibleExistingProjects] = useState([])

    useEffect(() => {
        if (preSelectedProgram?.label) {
            setNewProjects(
                newProjects.map((proj) => ({
                    ...proj,
                    selectedProgram: preSelectedProgram,
                })),
            )
        }
    }, [preSelectedProgram])

    const generateImportProjectList = () => {
        const existingProjectKeys: string[] = []
        projects.forEach((project) => {
            existingProjectKeys.push(project.program_project_key)
        })

        setVisibleExistingProjects(
            projectsInBE.filter((proj) => {
                const projectExists = existingProjectKeys.includes(proj.program_project_key)
                return (
                    proj.program_project_year === selectedYearFilter.value &&
                    !projectExists &&
                    proj.program_name === programMetadata.program_name
                )
            }),
        )
    }

    useEffect(() => {
        generateImportProjectList()
    }, [selectedYearFilter, yearFilterOptions])

    useEffect(() => {
        generateImportProjectList()
    }, [])

    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(visibleExistingProjects, {
        propertyFiltering: {
            filteringProperties: PROJECT_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No project'
                    subtitle='No existing project to display.'
                    action={<></>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[1],
            },
        },
        selection: {},
    })

    const handleProjectsCreation = () => {
        const payload = { requester: userAlias }
        payload['projects'] = generateProjectPayload()

        if (payload['projects'].length === 0) {
            setAlertItemsTemplate('error', 'Project(s) is invalid.')
            return
        }

        onProjectLoadingChange(true)
        createProjects(payload)
            .then((res) => {
                onProjectsChange()
                setAlertType('success')
                if (mode === ModalModes.IMPORT) {
                    setAlertContent(
                        `Successfully imported project(s) ${selectedImportProjects.map((prj) => prj.project_name)}.`,
                    )
                } else if (mode === ModalModes.CREATE) {
                    setAlertContent(
                        `Successfully created project(s) ${newProjects.map((prj) => prj.projectName)}.`,
                    )
                }
                onProjectLoadingChange(false)
            })
            .catch((error) => {
                setAlertType('error')
                setAlertContent(
                    `Failed to create project(s) ${newProjects.map((prj) => prj.projectName)}: ${
                        error.response.data
                    }.`,
                )
                console.error(error)
                onProjectLoadingChange(false)
            })
        onBulkModalDismiss()
        setNewProjects([createNewProjectBulkTable()])
    }

    const setAlertItemsTemplate = (type: string, content: string) => {
        setAlertItems([
            {
                type: type,
                content: content,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setAlertItems([]),
            },
        ])
    }

    const getNewProjectPayload = () => {
        const projectPayload: any[] = []
        const latestYear = yearFilterOptions[0].value
        for (const project of newProjects) {
            if (
                !project.projectName ||
                project.projectNameError ||
                !project.selectedProgram.value
            ) {
                return []
            }
            const programIds = project.selectedProgram.value
                ? project.selectedProgram.value.split(PROGRAM_ID_SEPARATOR)
                : ['', '']

            projectPayload.push({
                business_entity_id: selectBusinessEntity.id,
                program_id: programIds[0],
                local_program_id: programIds[1],
                project_id: project.projectId,
                program_project_year: preSelectedProgram.value
                    ? programMetadata.program_project_year
                    : latestYear,
                project_name: project.projectName.trim(),
                is_active: project.isActive ? 'True' : 'False',
                description: project.description,
                program_name: project.selectedProgram.label,
            })
        }

        return projectPayload
    }

    const getImportProjectPayload = () => {
        const projectPayload: any[] = []
        const date = new Date()
        const year = date.getFullYear().toString()
        for (const project of selectedImportProjects) {
            const programIds = preSelectedProgram?.value
                ? preSelectedProgram.value.split(PROGRAM_ID_SEPARATOR)
                : ['', '']
            projectPayload.push({
                business_entity_id: selectBusinessEntity.id,
                program_id: programIds[0],
                local_program_id: programIds[1],
                project_id: project.project_id,
                program_project_year: programMetadata ? programMetadata.program_project_year : year,
                project_name: project.project_name.trim(),
                is_active: project.is_active ?? 'True',
                active_status_effective_date: project.active_status_effective_date ?? '',
                description: project.description,
                program_name: preSelectedProgram.label,
            })
        }
        return projectPayload
    }

    const generateProjectPayload = () => {
        return mode === ModalModes.CREATE ? getNewProjectPayload() : getImportProjectPayload()
    }

    const createProjects = (payload) => {
        return apiClient.post(`/projects/bulk-create`, JSON.stringify(payload))
    }

    const handleNewProjectAdd = (id: string) => {
        setNewProjects(getNewProjectAdd(newProjects, id, preSelectedProgram))
    }

    const handleNewProjectDelete = (id: string) => {
        const tempNewProjects = getNewProjectDelete(newProjects, id)
        if (tempNewProjects.length === 0) {
            setAlertItemsTemplate(
                'error',
                'At least one project is required for project(s) creation.',
            )
            return
        }
        setNewProjects(tempNewProjects)
    }

    const handleNewProjectChange = (id: string, type: string, value: string) => {
        setNewProjects(getNewProjectChange(newProjects, id, type, value))
    }

    const importVisibleColumns = [
        'project_name',
        'program_project_year',
        'description',
        'program_name',
        'is_active',
    ]

    return (
        <Modal
            onDismiss={() => onBulkModalDismiss()}
            header={
                <SpaceBetween direction='vertical' size='s'>
                    {mode === ModalModes.CREATE ? 'Create Project(s)' : 'Import Project(s)'}
                    <Flashbar items={alertItems} />
                </SpaceBetween>
            }
            visible={bulkModalVisible}
            size='max'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onBulkModalDismiss}>
                            Cancel
                        </Button>
                        <Button variant='primary' onClick={handleProjectsCreation}>
                            {mode === ModalModes.CREATE ? 'Create Project(s)' : 'Import Project(s)'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {mode === ModalModes.CREATE ? (
                <BulkCreateProjectTable
                    projects={projects}
                    newProjects={newProjects}
                    onNewProjectAdd={handleNewProjectAdd}
                    onNewProjectDelete={handleNewProjectDelete}
                    onNewProjectChange={handleNewProjectChange}
                    isProgramPreSelected={isProgramPreSelected}
                    isLoading={isProgramLoading}
                    selectedYear={yearFilterOptions[0].value}
                />
            ) : (
                <Table
                    {...collectionProps}
                    wrapLines
                    stickyHeader={true}
                    empty={
                        <EmptyState
                            title='No projects'
                            subtitle={'No existing projects to import.'}
                            action={<></>}
                        />
                    }
                    selectedItems={selectedImportProjects}
                    onSelectionChange={({ detail }) => {
                        setSelectedImportProjects(detail.selectedItems)
                    }}
                    columnDefinitions={columns}
                    items={items}
                    loading={isLoading}
                    loadingText='Loading Projects'
                    visibleColumns={importVisibleColumns}
                    selectionType={'multi'}
                    pagination={<Pagination {...paginationProps} />}
                    filter={
                        <SpaceBetween size={'s'} direction={'horizontal'}>
                            <PropertyFilter
                                i18nStrings={PROPERTY_FILTER_I18NSTRING}
                                {...propertyFilterProps}
                                countText={getMatchesCountText(filteredItemsCount)}
                            />
                            <Select
                                selectedOption={selectedYearFilter}
                                onChange={({ detail }) =>
                                    setSelectedYearFilter(detail.selectedOption)
                                }
                                options={yearFilterOptions}
                            />
                        </SpaceBetween>
                    }
                />
            )}
        </Modal>
    )
}

export default BulkCreateImportProject

import React, { useEffect, useReducer, useState } from 'react'
import ProjectList from './ProjectList'
import { useAppContext } from '../../../context'
import { Alert, Box, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react'
import {
    formatYearSelection,
    getDateFormat,
    isProgramProjectActive,
    isProgramProjectActiveForYear,
} from '../../common/Util'
import {
    AlertTypes,
    EMPTY_SELECTION,
    EMPTY_YEAR_SELECTION,
    ModalModes,
    PROGRAM_ID_SEPARATOR,
} from '../../Constant'
import useStore from '../../Store'
import HeaderTemplate from '../reusable/HeaderTemplate'
import BulkCreateImportProject from './BulkCreateImportProject'
import DeleteModal from '../reusable/DeleteModal'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import UpdateProject from './UpdateProject'
import MoveProjectsModal from './MoveProjectsModal'
import { LIMITED_ACCESS_MESSAGE } from '../reusable/TextUtil'
import { CREATE_PROJECT_REQUEST } from '../../common/LinkUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import GenericSummaryTable from '../reusable/GenericSummaryTable'
import { Link } from '@amzn/awsui-components-react/polaris'
import {
    getProgramColumnDefinitions,
    getProgramSummaryVisibleColumns,
    programAttributes,
} from '../program/ProgramAttributes'
import {
    convertBoolStringToBool,
    filterVisibleColsByTerms,
    programReducer,
    REDUCER_ACTIONS,
} from '../reusable/Utils'
import CreateProgram from '../program/CreateProgram'
import { generateInitialState, mapToTokenItem } from '../program/ProgramConstants'
import { YEAR_OPTIONS } from '../../../const/options'
import { projectListColumnDef } from './projectUtil'

const SetupProject = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const paths = useLocation().pathname.split('/')
    const isProgramSelected = paths.includes('program')
    const parentProgramId = isProgramSelected ? paths[2] : ''
    const localProgramId = isProgramSelected ? paths[3] : ''
    const navigate = useNavigate()

    const canAdmin = useStore((state) => state.canAdmin)
    const projects = useStore((state) => state.allProjects)
    const setProjects = useStore((state) => state.setAllProjects)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [programMeta, setProgramMeta] = useState<any>({})
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [bulkModalVisible, setBulkModalVisible] = useState(false)
    const [moveProjectsModalVisible, setMoveProjectsModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<ModalModes>(ModalModes.NOT_SET)
    const [projectsInBE, setProjectsInBE] = useState<any>([])
    const [selectedProjects, setSelectedProjects] = useState<any>([])
    const [alertContent, setAlertContent] = useState<string>('')
    const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.INFO)
    const [isProjectLoading, setIsProjectLoading] = useState(true)
    const [visibleProjects, setVisibleProjects] = useState([])
    const [isProgramLoading, setIsProgramLoading] = useState(isProgramSelected)
    const [importLoading, setImportLoading] = useState(true)
    const [programViewModalVisible, setProgramViewModalVisible] = useState(false)
    const [programModalExpanded, setProgramModalExpanded] = useState(true)
    const [selectedYearFilter, setSelectedYearFilter] = useState(EMPTY_SELECTION)
    const [yearFilterOptions, setYearFilterOptions] = useState<any>([EMPTY_SELECTION])

    const [programState, dispatchProgram] = useReducer(
        programReducer,
        generateInitialState(programAttributes),
    )

    const loadSelect = (target) => {
        dispatchProgram({
            type: REDUCER_ACTIONS.load,
            target: target,
        })
        return
    }
    const isProjectListReadonly = () => {
        return (
            canAdmin &&
            isProgramSelected &&
            programMeta !== undefined &&
            !programMeta.is_active_for_year
        )
    }
    const [showActiveProjects, setShowActiveProjects] = useState(false)
    const programColumns = isProgramSelected
        ? getProgramColumnDefinitions(navigate, programAttributes)
        : []
    const visibleProgramColumns = isProgramSelected ? getProgramSummaryVisibleColumns() : []
    const getProgramProjects = () => {
        apiClient
            .get(`$/program/${parentProgramId}/${localProgramId}/projects`)
            .then((res) => {
                const allProjects = res.data
                setProjects(allProjects)
                filterActiveProjects(allProjects)
                setIsProjectLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsProjectLoading(false)
            })
    }

    useEffect(() => {
        if (parentProgramId && localProgramId) {
            getProgramProjects()
        }
    }, [showActiveProjects])

    const getParentProgram = () => {
        setIsProgramLoading(true)
        apiClient
            .get(`/program/${parentProgramId}/${localProgramId}`)
            .then((res) => {
                const program = res.data
                const tmpProgram =
                    program !== undefined
                        ? {
                              ...program,
                              stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                              registered_users: program['registered_users'] || [],
                              is_active: isProgramProjectActive(program),
                              is_active_for_year: isProgramProjectActiveForYear(program),
                              is_op_goal: convertBoolStringToBool(program.is_op_goal),
                          }
                        : undefined
                setProgramMeta(tmpProgram)
                setIsProgramLoading(false)
                getProgramProjects()
            })
            .catch((err) => {
                console.error(err)
                setIsProgramLoading(false)
            })
    }

    useEffect(() => {
        if (!localProgramId || !parentProgramId) {
            return
        }
        getParentProgram()
    }, [localProgramId, parentProgramId])
    const handleCreate = () => {
        setModalMode(ModalModes.CREATE)
        setBulkModalVisible(true)
    }

    const handleImport = () => {
        setModalMode(ModalModes.IMPORT)
        getAllProjectsByBE()
        setBulkModalVisible(true)
    }

    const handleEditModalClose = () => {
        setEditModalVisible(false)
    }

    const handleEdit = () => {
        setModalMode(ModalModes.EDIT)
        setEditModalVisible(true)
    }

    const handleMoveProjects = () => {
        setMoveProjectsModalVisible(true)
    }

    const handleMoveProjectsModalClose = () => {
        setMoveProjectsModalVisible(false)
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleBulkModalClose = () => {
        setBulkModalVisible(false)
    }

    useEffect(() => {
        if (!canAdmin) {
            return
        }
        const itemOptions = !showActiveProjects
            ? [...projects]
            : projects.filter((proj) => isProgramProjectActiveForYear(proj))
        setVisibleProjects(itemOptions)
    }, [projects, showActiveProjects])

    useEffect(() => {
        // ensure we de-select items that should have been filtered out
        if (!selectedProjects || !selectedProjects.length) {
            return
        }
        setSelectedProjects(
            selectedProjects.filter(
                (project) => !(showActiveProjects && project.is_active_for_year !== 'True'),
            ),
        )
    }, [showActiveProjects])
    useEffect(() => {
        setYearFilterOptions([EMPTY_SELECTION])
        setSelectedYearFilter(EMPTY_SELECTION)
        setSelectedProjects([])
        getYearsInBusinessEntity()
    }, [selectBusinessEntity])
    const getYearsInBusinessEntity = () => {
        if (!selectBusinessEntity?.id) {
            return
        }
        apiClient
            .get(`/years/${selectBusinessEntity.id}`)
            .then((res) => {
                const formattedYears = formatYearSelection(res.data)
                setYearFilterOptions(
                    formattedYears.length ? formattedYears : [EMPTY_YEAR_SELECTION],
                )
            })
            .catch((err) => {
                console.error(err)
                setYearFilterOptions([EMPTY_YEAR_SELECTION])
                setSelectedYearFilter(EMPTY_YEAR_SELECTION)
            })
    }

    const getAllProjectsByBE = () => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/projects`)
            .then((response) => {
                setProjectsInBE(response.data)
                setImportLoading(false)
            })
            .catch((e) => {
                console.error(e)
                setProjectsInBE([])
                setImportLoading(false)
            })
    }

    const getAllProjectsByBusinessEntityByYear = () => {
        setIsProjectLoading(true)
        apiClient
            .get(
                `/business-entity/${selectBusinessEntity.id}/year/${selectedYearFilter.value}/projects`,
            )
            .then((response) => {
                const allProjects = response.data
                canAdmin
                    ? setProjects(allProjects)
                    : setProjects(allProjects.filter((proj) => isProgramProjectActiveForYear(proj)))
                const onlyActiveProjects = allProjects.filter((proj) =>
                    isProgramProjectActiveForYear(proj),
                )
                if (canAdmin) {
                    setProjects(allProjects)
                    setVisibleProjects(showActiveProjects ? onlyActiveProjects : allProjects)
                } else {
                    setProjects(onlyActiveProjects)
                    setVisibleProjects(onlyActiveProjects)
                }
                setIsProjectLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setProjects([])
                setVisibleProjects([])
                setIsProjectLoading(false)
            })
    }
    const filterActiveProjects = (allProjects) => {
        const onlyActiveProjects = allProjects.filter((proj) => isProgramProjectActiveForYear(proj))
        if (canAdmin) {
            setVisibleProjects(showActiveProjects ? onlyActiveProjects : allProjects)
        } else {
            setVisibleProjects(onlyActiveProjects)
        }
    }

    const deleteProjects = () => {
        const program_id = selectedProjects[0].program_id
        const project_ids = selectedProjects.map((project) => project['project_id']).join(',')
        const local_program_ids = selectedProjects
            .map((project) => project['local_program_id'])
            .join(',')
        setDeleteModalVisible(false)
        setIsProjectLoading(true)
        apiClient
            .delete(
                `/program/${program_id}/projects?local_program_ids=${local_program_ids}&project_ids=${project_ids}`,
            )
            .then((response) => {
                refreshProjects()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(
                    `Successfully deleted project(s) ${selectedProjects.map(
                        (prj) => prj.project_name,
                    )}.`,
                )
                setSelectedProjects([])
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to delete project(s) ${selectedProjects.map(
                        (prj) => prj.project_name,
                    )}: ${error.response.data}`,
                )
                setSelectedProjects([])
                setIsProjectLoading(false)
            })
    }
    const refreshProjects = () => {
        if (isProgramSelected) {
            getProgramProjects()
            getParentProgram()
            return
        }
        getAllProjectsByBusinessEntityByYear()
    }

    useEffect(() => {
        if (!selectBusinessEntity.id) {
            setProjects([])
            setIsProjectLoading(false)
            return
        }
        if (!isProgramSelected && selectedYearFilter?.value) {
            BusinessEntityRefresh(selectBusinessEntity.id, getAllProjectsByBusinessEntityByYear)
        } else if (
            programMeta &&
            programMeta['program_id'] &&
            programMeta['is_core_program'] !== 'True' &&
            programMeta['business_entity_id'] !== selectBusinessEntity.id
        ) {
            {
                navigate('/programs')
                return
            }
        } else {
            if (selectedYearFilter?.value === 'no_year_found') {
                setProjects([])
                setIsProjectLoading(false)
            }
        }
        getAllProjectsByBE()
    }, [selectBusinessEntity, alertType, alertContent, selectedYearFilter])

    useEffect(() => {
        setSelectedYearFilter(
            yearFilterOptions[0] === EMPTY_YEAR_SELECTION
                ? EMPTY_YEAR_SELECTION
                : yearFilterOptions[0],
        )
    }, [yearFilterOptions])

    const projectVisibleColumns = canAdmin
        ? [
              'project_name',
              'program_project_year',
              'description',
              'is_active_for_year',
              'program_name',
              'created_by',
              'updated_by',
              'created_at',
              'updated_at',
          ]
        : [
              'project_name',
              'program_project_year',
              'description',
              'program_name',
              'created_by',
              'updated_by',
              'created_at',
              'updated_at',
          ]

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                ...(isProgramSelected
                                    ? [
                                          { text: `Programs`, href: '/programs' },
                                          {
                                              text: `${programMeta?.program_name ?? ''} Projects`,
                                              href: '#',
                                          },
                                      ]
                                    : [
                                          {
                                              text: `${programMeta?.program_name ?? ''} Projects`,
                                              href: '#',
                                          },
                                      ]),
                            ]}
                        />
                        {alertContent !== '' && (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                        {isProjectListReadonly() && (
                            <Alert>Readonly mode for inactive program.</Alert>
                        )}

                        {!canAdmin &&
                            !selectBusinessEntity.configurations.project.non_admin_creation && (
                                <Alert header='Limited Access'>
                                    <LIMITED_ACCESS_MESSAGE
                                        item='project'
                                        link={CREATE_PROJECT_REQUEST}
                                        slackId={
                                            selectBusinessEntity.slack_channels[0].slack_channel_id
                                        }
                                        slackName={
                                            selectBusinessEntity.slack_channels[0]
                                                .slack_channel_name
                                        }
                                    />
                                </Alert>
                            )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title='Project(s)'
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deleteProjects}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween size={'xxl'} direction={'vertical'}>
                    {isProgramSelected && (
                        <GenericSummaryTable
                            itemsToShow={[programMeta]}
                            actions={[]}
                            columns={[
                                {
                                    id: 'prog_name_display',
                                    header: 'Program',
                                    cell: (e) => (
                                        <Link
                                            onFollow={() => {
                                                loadSelect(programMeta)
                                                setProgramViewModalVisible(true)
                                            }}
                                        >
                                            {e.program_name}
                                        </Link>
                                    ),
                                    sortingField: 'program_name',
                                },
                                ...programColumns.slice(1),
                            ]}
                            visibleColumns={filterVisibleColsByTerms(visibleProgramColumns, [
                                'create',
                                'update',
                                'program_name',
                            ])}
                            defaultNameField={'Program'}
                            nameField={'program_name'}
                            isLoading={isProgramLoading || isProjectLoading}
                            objectType={'Program'}
                            wrapLines={false}
                            includePagination={false}
                        />
                    )}
                    <CreateProgram
                        setModalMode={false}
                        initialActiveEffectiveDate={
                            programMeta && programMeta.active_status_effective_date
                                ? programMeta.active_status_effective_date.split('T')[0]
                                : getDateFormat(new Date())
                        }
                        initialIsActive={true}
                        refreshList={() => {}}
                        requester={''}
                        handleAddAlertContent={() => {}}
                        state={{
                            ...programState,
                            ['kingpin_goals']: programMeta?.kingpin_goals || [],
                            ['important_links']: programMeta?.important_links || [],
                        }}
                        inputChange={() => {}}
                        clearAllInputs={() => {}}
                        isDataUnmodified={true}
                        isInputInvalid={false}
                        onIsInputInvalidChanged={() => {}}
                        onIsDataUnmodifiedChanged={() => {}}
                        isAddingProgram={false}
                        closeModalHandler={() => setProgramViewModalVisible(false)}
                        visible={programViewModalVisible}
                        setIsProgramLoading={false}
                        isModalExpanded={programModalExpanded}
                        onIsModalExpandedChanged={setProgramModalExpanded}
                        modalMode={ModalModes.VIEW}
                        programs={programMeta ? [programMeta] : []}
                        selectedPrograms={programMeta ? [programMeta] : []}
                        selectedBusinessEntityFilter={{
                            label: selectBusinessEntity.name,
                            value: selectBusinessEntity.id,
                        }}
                        initialKingpinGoals={
                            programMeta?.kingpin_goals ? programMeta.kingpin_goals : []
                        }
                        initialImportantLinks={
                            programMeta?.important_links ? programMeta.important_links : []
                        }
                        globalAttributesError={''}
                        setGlobalAttributesError={() => {}}
                        isLoadingGlobalAttrs={false}
                        isLoadingParentProgramPlans={false}
                        allParentProgramPlans={[]}
                        loadSelectProgram={() => {}}
                        allParentProgramGlobalAttributes={[]}
                        fetchGlobalAttributesForParentProgramAndYear={() => {}}
                    />
                    <ProjectList
                        header={undefined}
                        projects={visibleProjects}
                        selectedProjects={selectedProjects}
                        setSelectedProjects={setSelectedProjects}
                        columns={projectListColumnDef}
                        visibleColumns={projectVisibleColumns}
                        onCreate={handleCreate}
                        onImport={handleImport}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onMoveProjects={handleMoveProjects}
                        isLoading={isProjectLoading}
                        canAdmin={canAdmin}
                        showActiveProjects={showActiveProjects}
                        setShowActiveProjects={setShowActiveProjects}
                        isListReadonly={isProjectListReadonly()}
                        yearFilterOptions={yearFilterOptions}
                        selectedYearFilter={selectedYearFilter}
                        setSelectedYearFilter={setSelectedYearFilter}
                        hasParentProgram={localProgramId && parentProgramId}
                    />
                </SpaceBetween>
            </Box>
            <UpdateProject
                projects={projects}
                modalMode={modalMode}
                modalVisible={editModalVisible}
                closeModalHandler={handleEditModalClose}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
                setAlertContent={setAlertContent}
                setAlertType={setAlertType}
                refreshList={refreshProjects}
            />
            <BulkCreateImportProject
                projects={projects}
                projectsInBE={projectsInBE}
                columns={projectListColumnDef}
                preSelectedProgram={
                    isProgramSelected
                        ? {
                              label: programMeta?.program_name ?? '',
                              value: `${parentProgramId}${PROGRAM_ID_SEPARATOR}${localProgramId}`,
                          }
                        : {
                              label: 'Select a program',
                              value: '',
                          }
                }
                bulkModalVisible={bulkModalVisible}
                onBulkModalDismiss={handleBulkModalClose}
                onProjectLoadingChange={setIsProjectLoading}
                onProjectsChange={refreshProjects}
                isProgramLoading={isProgramLoading}
                setAlertContent={setAlertContent}
                setAlertType={setAlertType}
                isProgramPreSelected={isProgramSelected}
                programMetadata={programMeta}
                yearFilterOptions={yearFilterOptions}
                selectedYearFilter={selectedYearFilter}
                setSelectedYearFilter={setSelectedYearFilter}
                isLoading={importLoading}
                mode={modalMode}
            />
            <MoveProjectsModal
                modalVisible={moveProjectsModalVisible}
                closeModalHandler={handleMoveProjectsModalClose}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
                setAlertContent={setAlertContent}
                setAlertType={setAlertType}
                refreshProjects={refreshProjects}
            />
        </ContentLayout>
    )
}

export default SetupProject
